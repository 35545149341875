<template>
    <SubmitButton v-if="order.canArchive" :loading="loading" classes="btn btn-secondary btn-sm btn-block" @click.prevent="archiveOrder">
        <i class="fa fa-archive mr-2"/>
        <span v-html="$t('OrderArchiveButton.archiveOrder')"/>
    </SubmitButton>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import Order from "@/classes/Order";

export default {
    name: "OrderArchiveButton",
    components: { SubmitButton },
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
    data() {
        return { loading: false };
    },
    methods: {
        ...mapActions("order", { updateOrder: "updateOrder" }),
        confirmArchiveOrder() {
            return Swal.fire({
                title: this.$t("OrderArchiveButton.areYouSure"),
                text: this.$t("OrderArchiveButton.archiveOrderIsIrreversible"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("OrderArchiveButton.cancel"),
            });
        },
        async archiveOrder() {
            try {
                const { isConfirmed } = await this.confirmArchiveOrder();
                if (isConfirmed) {
                    this.loading = true;
                    const { data } = await this.$gutenbergOneRobotAPI.patchOrder(this.order._id, { isArchived: true });
                    this.updateOrder(data);
                    this.$toasted.success(this.$t("OrderArchiveButton.orderArchived"), { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>