<template>
    <div class="page-header">
        <div class="row align-items-end">
            <div class="col-sm mb-2 mb-sm-0">
                <h1 class="page-title text-center text-primary">
                    <slot/>
                </h1>
            </div>
            <div class="col-sm-auto">
                <slot name="right"/>
            </div>
        </div>
    </div>
</template>

<script>
export default { name: "PageHeader" };
</script>

<style scoped>
    .page-header {
        padding-bottom: 0.25rem;
        margin-bottom: 1.55rem;
    }
</style>