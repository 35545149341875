<template>
    <div id="control-panel-printers" class="row align-items-start">
        <div class="col-12">
            <h3 class="text-primary">
                <i class="fa fa-print mr-2"/>
                <span v-html="$t('ControlPanelPrinters.printers')"/>
            </h3>
            <hr/>
        </div>
        <ControlPanelPrinter :printer="robotCorpusPrinter" printer-type="corpus" class="col-12"/>
        <ControlPanelPrinter :printer="robotCoverPrinter" printer-type="cover" class="col-12"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ControlPanelPrinter from "@/components/ControlPanel/ControlPanelPrinters/ControlPanelPrinter";

export default {
    name: "ControlPanelPrinters",
    components: { ControlPanelPrinter },
    computed: {
        ...mapGetters("robotStatus", {
            robotCoverPrinter: "robotCoverPrinter",
            robotCorpusPrinter: "robotCorpusPrinter",
        }),
    },
};
</script>

<style scoped>

</style>