<template>
    <div class="order-production-status text-center alert p-1 mb-0" :class="`alert-${alertClass}`"
         v-html="$t(`OrderProductionStatus.status.${order.status.production}`)"/>
</template>

<script>
import Order from "@/classes/Order";

export default {
    name: "OrderProductionStatus",
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
    computed: {
        alertClass() {
            const productionStatusAlertClass = {
                pending: "warning",
                preparing: "info",
                delivered: "success",
                canceled: "danger",
                printed: "success",
            };
            return productionStatusAlertClass[this.order.status.production];
        },
    },
};
</script>

<style lang="scss" scoped>
    .order-production-status {
        transition: all 0.5s ease;
    }
</style>