<template>
    <button v-if="order.needsPayment" class="btn btn-outline-primary btn-sm btn-block"
            @click="$emit('show-order-payment-modal', order)">
        <i class="fa fa-euro-sign mr-2"/>
        <span v-html="$t('OrderPaymentButton.payOrder')"/>
    </button>
</template>

<script>
import Order from "@/classes/Order";

export default {
    name: "OrderPaymentButton",
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>