<template>
    <div id="order-print-modal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div v-if="order" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="$t('OrderPrintModal.printOrderForClient', order)"/>
                </div>
                <div class="modal-body pb-3">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h4 class="text-secondary" v-html="$t('OrderPrintModal.youReAboutToPrintOrder', order)"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <OrderProductList :order="order" mode="print"/>
                        </div>
                    </div>
                    <transition mode="out-in" name="fade">
                        <JobPrintingProgress v-if="order.isPrinting" key="product-print-progress" :job="order.printingProduct.processingJob"/>
                        <div v-else-if="order.isInPrintingQueue" id="pending-print-disclaimer" key="pending-print-disclaimer" class="text-muted">
                            <div class="d-flex align-items-center justify-content-center my-2">
                                <i class="fa fa-clock fa-2x mr-3"/>
                                <span v-html="$t('OrderPrintModal.oneOfOrderProductIsAboutToPrint')"/>
                            </div>
                        </div>
                        <div v-else-if="order.isFullyPrinted" id="order-fully-printed-disclaimer"
                             key="order-fully-printed-disclaimer" class="text-success">
                            <div class="d-flex align-items-center justify-content-center my-2">
                                <i class="fa fa-check-circle fa-2x mr-3"/>
                                <span v-html="$t('OrderPrintModal.thisOrderIsFullyPrinted')"/>
                            </div>
                        </div>
                        <div v-else id="starting-print-disclaimer" key="starting-print-disclaimer" class="text-muted">
                            <div class="d-flex align-items-center">
                                <div v-html="$t('OrderPrintModal.toBeginClickOnPrintProduct')"/>
                                <div class="fa fa-reply fa-2x fa-rotate-90 text-success ml-4"/>
                            </div>
                            <hr/>
                            <div class="text-center text-uppercase text-success font-weight-bolder my-1" v-html="$t('OrderPrintModal.or')"/>
                            <hr/>
                            <div class="d-flex">
                                <div v-html="$t('OrderPrintModal.printAllProductsAtOnce')"/>
                                <div class="fa fa-reply fa-2x fa-rotate-270 text-success ml-4"/>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="modal-footer d-flex justify-content-between align-items-center">
                    <div>
                        <div v-if="order.isInPrintingQueue">
                            <div v-html="$t('OrderPrintModal.orderPrintingProgress')"/>
                            <div class="progress my-1">
                                <div class="progress-bar progress-bar-striped" role="progressbar"
                                     :style="{ width: `${order.printingProgressPercent}%` }"/>
                            </div>
                            <div class="text-center" v-html="`${order.printingProgressPercent}%`"/>
                        </div>
                    </div>
                    <div class="d-flex">
                        <SubmitButton v-if="!order.isFullyPrinted && !order.isInPrintingQueue && order.isPrintable"
                                      classes="btn btn-success btn-block" :loading="loading" @click="printOrder">
                            <i class="fa fa-print mr-2"/>
                            <span v-html="$t('OrderPrintModal.printOrder')"/>
                        </SubmitButton>
                        <SubmitButton v-else-if="order.status === 'delivered'" classes="btn btn-success btn-block" :loading="loading"
                                      @click="deliverOrder">
                            <i class="fa fa-check-circle mr-2"/>
                            <span v-html="$t('OrderPrintModal.deliverOrder')"/>
                        </SubmitButton>
                        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" :disabled="loading"
                                v-html="$t('OrderPrintModal.close')"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
import $ from "jquery";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import OrderProductList from "@/components/shared/Orders/Products/OrderProductList";
import JobPrintingProgress from "@/components/shared/Jobs/JobPrintingProgress";

export default {
    name: "OrderPrintModal",
    components: { JobPrintingProgress, OrderProductList, SubmitButton },
    data() {
        return {
            selectedOrderId: undefined,
            loading: false,
        };
    },
    computed: {
        ...mapGetters("order", { orders: "orders" }),
        ...mapGetters("robotStatus", { robotStatus: "robotStatus" }),
        order() {
            return this.orders.find(({ _id }) => _id === this.selectedOrderId);
        },
    },
    methods: {
        ...mapActions("order", { updateOrder: "updateOrder" }),
        show(orderId) {
            this.selectedOrderId = orderId;
            $("#order-print-modal").modal("show");
        },
        hide() {
            $("#order-print-modal").modal("hide");
        },
        confirmPrintOrder() {
            return Swal.fire({
                title: this.$t("OrderPrintModal.areYouSure"),
                text: this.$t("OrderPrintModal.youWillPrintAllOrder"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("OrderPrintModal.cancel"),
            });
        },
        async printOrder() {
            try {
                const { isConfirmed } = await this.confirmPrintOrder();
                if (isConfirmed) {
                    this.loading = true;
                    const promises = [];
                    for (const product of this.order.products) {
                        const doneJobs = this.order.getDoneJobsForProduct(product._id);
                        const jobsToProduceCount = product.quantity - doneJobs.length;
                        for (let i = 0; i < jobsToProduceCount; i++) {
                            promises.push(this.$gutenbergOneRobotAPI.addJobToProduct(this.order._id, product._id));
                        }
                    }
                    await Promise.all(promises);
                    this.$toasted.success(this.$t("OrderPrintModal.printOrderStarted"), { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading = false;
            }
        },
        confirmDeliverOrder() {
            return Swal.fire({
                title: this.$t("OrderPrintModal.areYouSure"),
                text: this.$t("OrderPrintModal.deliverOrderIsIrreversible"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("OrderPrintModal.cancel"),
            });
        },
        async deliverOrder() {
            try {
                const { isConfirmed } = await this.confirmDeliverOrder();
                if (isConfirmed) {
                    this.loading = true;
                    const { data } = await this.$gutenbergOneRobotAPI.patchOrder(this.order._id, { status: { production: "delivered" } });
                    this.updateOrder(data);
                    this.hide();
                    this.$toasted.success(this.$t("OrderPrintModal.orderDelivered"), { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #starting-print-disclaimer {
        font-size: 1rem;
    }

    #pending-print-disclaimer {
        font-size: 1.25rem;
    }

    #order-fully-printed-disclaimer {
        font-size: 1.25rem;
    }
</style>