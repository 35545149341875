import Vue from "vue";
import Order from "@/classes/Order";
import { getOrdersByPriority } from "@/helpers/functions/Order";

export default {
    namespaced: true,
    state: { orders: undefined },
    getters: {
        orders(state) {
            return state.orders;
        },
        printingOrder(state) {
            return state.orders.find(({ isPrinting }) => isPrinting);
        },
        printingQueueJobs(state) {
            const printingQueueOrders = state.orders.filter(({ isInPrintingQueue }) => isInPrintingQueue);
            const printingQueueJobs = [];
            for (const order of printingQueueOrders) {
                const printingQueueProducts = order.products.filter(({ isInPrintingQueue }) => isInPrintingQueue);
                for (const product of printingQueueProducts) {
                    // Add priority
                    product.jobsInPrintingQueue.forEach(item => {
                        item.priority = order.priority;
                    });
                    printingQueueJobs.push(...product.jobsInPrintingQueue);
                }
            }
            const printingQueueJobsSortDate = printingQueueJobs.sort((a, b) => a.createdAt - b.createdAt);
            const printingQueueJobsSortPriority = printingQueueJobsSortDate.sort(getOrdersByPriority);
            return printingQueueJobsSortPriority;
        },
        erroredJobs(state) {
            const erroredJobs = [];
            for (const order of state.orders) {
                for (const product of order.products) {
                    erroredJobs.push(...product.erroredJobs);
                }
            }
            return erroredJobs.sort((a, b) => b.createdAt - a.createdAt);
        },
    },
    mutations: {
        setOrders(state, orders) {
            state.orders = orders.map(order => new Order(order));
        },
        addOrder(state, order) {
            state.orders.push(new Order(order));
        },
        updateOrder(state, order) {
            const orderIdx = state.orders.findIndex(({ _id }) => _id === order._id);
            if (orderIdx !== -1) {
                if (order.isArchived) {
                    state.orders.splice(orderIdx, 1);
                } else {
                    state.orders.splice(orderIdx, 1, new Order(order));
                }
            }
        },
    },
    actions: {
        async getAndSetOrders({ commit }) {
            const { data } = await Vue.prototype.$gutenbergOneRobotAPI.getOrders({ "is-archived": false });
            commit("setOrders", data);
        },
        addOrder({ commit }, order) {
            commit("addOrder", order);
        },
        updateOrder({ commit }, order) {
            commit("updateOrder", order);
        },
    },
};