<template>
    <SubmitButton v-if="order.canCancel" :loading="loading" classes="btn btn-outline-danger btn-sm btn-block" @click.prevent="cancelOrder">
        <i class="fa fa-times-circle mr-2"/>
        <span v-html="$t('OrderCancelButton.cancelOrder')"/>
    </SubmitButton>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import Order from "@/classes/Order";
import SubmitButton from "@/components/shared/Forms/SubmitButton";

export default {
    name: "OrderCancelButton",
    components: { SubmitButton },
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
    data() {
        return { loading: false };
    },
    methods: {
        ...mapActions("order", { updateOrder: "updateOrder" }),
        confirmCancelOrder() {
            return Swal.fire({
                title: this.$t("OrderCancelButton.areYouSure"),
                text: this.$t("OrderCancelButton.cancelOrderIsIrreversible"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("OrderCancelButton.cancel"),
            });
        },
        async cancelOrder() {
            try {
                const { isConfirmed } = await this.confirmCancelOrder();
                if (isConfirmed) {
                    this.loading = true;
                    const { data } = await this.$gutenbergOneRobotAPI.patchOrder(this.order._id, { status: { production: "canceled" } });
                    this.updateOrder(data);
                    this.$toasted.success(this.$t("OrderCancelButton.orderCanceled"), { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>