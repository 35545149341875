import Vue from "vue";
import VueRouter from "vue-router";
import Orders from "@/components/Orders/Orders";
import ControlPanel from "@/components/ControlPanel/ControlPanel";
import History from "@/components/History/History";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Orders",
        component: Orders,
    },
    {
        path: "/control-panel",
        name: "ControlPanel",
        component: ControlPanel,
    },
    {
        path: "/history",
        name: "History",
        component: History,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;