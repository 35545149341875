<template>
    <div class="order-payment-status text-center alert p-1 mb-0" :class="`alert-${alertClass}`">
        <div v-html="$t(`OrderPaymentStatus.status.${order.status.payment}`)"/>
        <div v-html="totalWT"/>
    </div>
</template>

<script>
import Order from "@/classes/Order";
import { formatPrice } from "@/helpers/functions/Price";

export default {
    name: "OrderPaymentStatus",
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
    computed: {
        alertClass() {
            const paymentStatusAlertClass = {
                waiting: "warning",
                errored: "danger",
                accepted: "success",
                refunded: "secondary",
            };
            return paymentStatusAlertClass[this.order.status.payment];
        },
        totalWT() {
            return formatPrice(this.order.totalWT);
        },
    },
};
</script>

<style lang="scss" scoped>
    .order-payment-status {
        transition: all 0.5s ease;
    }
</style>