<template>
    <SubmitButton :loading="loading" classes="btn btn-block btn-outline-success" @click="printProduct">
        <i class="fa fa-print mr-2"/>
        <span v-html="$t('ProductPrintButton.printThisProduct')"/>
    </SubmitButton>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import Order from "@/classes/Order";
import Product from "@/classes/Product";

export default {
    name: "ProductPrintButton",
    components: { SubmitButton },
    props: {
        order: {
            type: Order,
            required: true,
        },
        product: {
            type: Product,
            required: true,
        },
    },
    data() {
        return { loading: false };
    },
    methods: {
        ...mapActions("order", { updateOrder: "updateOrder" }),
        confirmPrintProduct() {
            return Swal.fire({
                title: this.$t("ProductPrintButton.areYouSure"),
                text: this.$tc("ProductPrintButton.addProductToPrintingQueue", 1, { title: this.product.title, quantity: 1 }),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("ProductPrintButton.cancel"),
            });
        },
        async printProduct() {
            try {
                const { isConfirmed } = await this.confirmPrintProduct();
                if (isConfirmed) {
                    this.loading = true;
                    const { data } = await this.$gutenbergOneRobotAPI.addJobToProduct(this.order._id, this.product._id);
                    this.updateOrder(data);
                    const toastText = this.$tc("ProductPrintButton.productAddedToPrintingQueue", 1, { title: this.product.title, quantity: 1 });
                    this.$toasted.success(toastText, { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>