import { getProp } from "@/helpers/functions/Class";
import Product from "@/classes/Product";

class Order {
    constructor(order = null) {
        this._id = getProp(order, "_id");
        this.client = getProp(order, "client");
        this.totalWT = getProp(order, "totalWT");
        this.currency = getProp(order, "currency");
        this.reference = getProp(order, "reference");
        this.status = {
            payment: getProp(order, "status.payment"),
            production: getProp(order, "status.production"),
        };
        this.products = getProp(order, "products", [], products => products.map(product => new Product(product)));
        this.comment = getProp(order, "comment");
        this.isArchived = getProp(order, "isArchived");
        this.orderedAt = getProp(order, "orderedAt", undefined, orderedAt => new Date(orderedAt));
        this.createdAt = getProp(order, "createdAt", undefined, createdAt => new Date(createdAt));
        this.updatedAt = getProp(order, "updatedAt", undefined, updatedAt => new Date(updatedAt));
        this.priority = getProp(order, "priority");
    }

    get needsPayment() {
        return this.status.payment === "waiting" || this.status.payment === "errored";
    }

    get canPrint() {
        return this.status.production !== "canceled";
    }

    get canCancel() {
        return this.status.production !== "delivered" && this.status.production !== "canceled" && this.status.production !== "printed";
    }

    get canArchive() {
        return this.status.production !== "delivered" && this.status.production !== "canceled" && this.status.production !== "printed";
    }

    get productCount() {
        return !this.isInPrintingQueue;
    }

    get printingProgressPercent() {
        let printedProductsCount = 0;
        let ret = 0;
        let productsCount = 0;
        for (const product of this.products) {
            printedProductsCount += product.doneJobs.length > product.quantity ? product.quantity : product.doneJobs.length;
            productsCount = product.quantity;
        }

        if (printedProductsCount > 0 && productsCount > 0) {
            ret = Math.round(printedProductsCount * 100 / productsCount);
        }

        return ret;
    }

    get isInPrintingQueue() {
        return this.products.some(({ isInPrintingQueue }) => isInPrintingQueue);
    }

    get isPrinting() {
        return this.products.some(({ isPrinting }) => isPrinting);
    }

    get isFullyPrinted() {
        return this.products.every(({ isFullyPrinted }) => isFullyPrinted);
    }

    get printingProduct() {
        return this.products.find(({ processingJob }) => processingJob);
    }

    getDoneJobsForProduct(productId) {
        const product = this.products.find(({ _id }) => _id === productId);
        return product ? product.doneJobs : [];
    }

    get isPrintable() {
        return this.products.every(({ isPrintable }) => isPrintable);
    }
}

export default Order;