import Vue from "vue";
import io from "socket.io-client";
import i18n from "@/plugins/vue-i18n";
import Config from "../../../config";

export default {
    namespaced: true,
    state: { socket: { orders: undefined, robotStatus: undefined } },
    getters: {
        ordersSocket(state) {
            return state.socket.orders;
        },
        robotStatusSocket(state) {
            return state.socket.robotStatus;
        },
    },
    mutations: {
        setOrdersSocket(state, socket) {
            state.socket.orders = socket;
        },
        setRobotStatusSocket(state, socket) {
            state.socket.robotStatus = socket;
        },
    },
    actions: {
        initializeSocketIO({ dispatch }) {
            dispatch("initializeOrdersSocket");
            dispatch("initializeRobotStatusSocket");
        },
        initializeOrdersSocket({ commit, dispatch }) {
            // eslint-disable-next-line new-cap
            const ordersSocket = new io(`${Config.API.gutenbergOneRobot.baseURL}/orders`);
            ordersSocket.on("connect", () => {
                Vue.toasted.success(i18n.t("SocketIO.orders.connect"), { icon: "check" });
            });
            ordersSocket.on("disconnect", () => {
                Vue.toasted.error(i18n.t("SocketIO.orders.disconnect"), { icon: "times" });
            });
            ordersSocket.on("orderCreated", async data => {
                await dispatch("order/addOrder", data, { root: true });
                Vue.toasted.success(i18n.t("SocketIO.orders.newOrder", { reference: data.reference }), { icon: "box" });
            });
            ordersSocket.on("orderUpdated", data => {
                dispatch("order/updateOrder", data, { root: true });
            });
            ordersSocket.on("ordersUpdated", data => {
                for (const order of data) {
                    dispatch("order/updateOrder", order, { root: true });
                }
            });
            commit("setOrdersSocket", ordersSocket);
        },
        initializeRobotStatusSocket({ commit, dispatch }) {
            // eslint-disable-next-line new-cap
            const robotStatusSocket = new io(`${Config.API.gutenbergOneRobot.baseURL}/robot-status`);
            robotStatusSocket.on("connect", () => {
                Vue.toasted.success(i18n.t("SocketIO.robotStatus.connect"), { icon: "check" });
            });
            robotStatusSocket.on("disconnect", () => {
                Vue.toasted.error(i18n.t("SocketIO.robotStatus.disconnect"), { icon: "times" });
            });
            robotStatusSocket.on("robotStatusUpdated", data => {
                dispatch("robotStatus/setRobotStatus", data, { root: true });
            });
            commit("setRobotStatusSocket", robotStatusSocket);
        },
    },
};