<template>
    <table class="order-product-list table table-striped table-hover table-bordered">
        <thead>
            <tr>
                <th class="text-center" scope="col" v-html="$t('OrderProductList.title')"/>
                <th v-if="mode === 'neutral'" class="text-center" scope="col" v-html="$t('OrderProductList.quantity')"/>
                <th v-else-if="mode === 'print'" class="text-center" scope="col" v-html="$t('OrderProductList.printing')"/>
                <th v-if="mode === 'print'" class="text-center" scope="col" v-html="$t('OrderProductList.actions')"/>
            </tr>
        </thead>
        <tbody>
            <tr v-for="product of order.products" :key="product._id">
                <th class="font-weight-bolder text-center align-middle">
                    <div class="d-flex justify-content-center align-items-center">
                        <ProductThumbnail class="order-product-thumbnail" :product="product"/>
                        <div class="ml-2">
                            <div class="product-title text-primary" v-html="product.title"/>
                            <div class="font-weight-bold font-italic" v-html="product.ISBN"/>
                            <div class="small font-italic" v-html="productDimensionsText(product)"/>
                            <div class="small font-italic" v-html="productPagesText(product)"/>
                        </div>
                    </div>
                    <hr class="my-1"/>
                    <ProductFilesStatus :order="order" :product="product"/>
                </th>
                <th v-if="mode === 'neutral'" class="text-center align-middle order-product-quantity text-primary" v-html="`x ${product.quantity}`"/>
                <th v-else-if="mode === 'print'" class="text-center align-middle">
                    <div>
                        <transition name="fade">
                            <i v-if="product.isFullyPrinted" class="fa fa-check-circle text-success mr-2"/>
                        </transition>
                        <span v-html="printedOrderProductCountText(product)"/>
                    </div>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped" role="progressbar"
                             :style="{ width: `${printedOrderProductPercent(product)}%` }"/>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div :key="currentlyInPrintingQueueProductText(product)" class="text-muted font-italic my-1"
                             v-html="currentlyInPrintingQueueProductText(product)"/>
                    </transition>
                    <transition name="fade" mode="out-in">
                        <div v-if="product.isPrinting" class="text-center text-success font-weight-bolder">
                            <i class="fa fa-spin fa-spinner mr-2"/>
                            <span v-html="$t('OrderProductList.currentlyPrinting')"/>
                        </div>
                    </transition>
                </th>
                <th v-if="mode === 'print'" class="text-center align-middle">
                    <ProductPrintButton v-if="product.isPrintable" :order="order" :product="product" class="mb-2"/>
                </th>
            </tr>
        </tbody>
    </table>
</template>

<script>
import Order from "@/classes/Order";
import ProductThumbnail from "@/components/shared/Products/ProductThumbnail";
import ProductPrintButton from "@/components/shared/Products/ProductPrintButton";
import ProductFilesStatus from "@/components/shared/Products/ProductFilesStatus";

export default {
    name: "OrderProductList",
    components: { ProductFilesStatus, ProductPrintButton, ProductThumbnail },
    props: {
        order: {
            type: Order,
            required: true,
        },
        mode: {
            type: String,
            default: "neutral",
        },
    },
    data() {
        return { loading: false };
    },
    methods: {
        printedOrderProductCountText(product) {
            const doneJobsForProduct = this.order.getDoneJobsForProduct(product._id);
            const printedCount = doneJobsForProduct.length;
            return this.$tc("OrderProductList.printedOrderProductCount", product.quantity, { printedCount, quantity: product.quantity });
        },
        printedOrderProductPercent(product) {
            const doneJobsForProduct = this.order.getDoneJobsForProduct(product._id);
            return Math.round(doneJobsForProduct.length * 100 / product.quantity);
        },
        currentlyInPrintingQueueProductText(product) {
            const count = product.jobsInPrintingQueue.length;
            return this.$tc("OrderProductList.currentlyInPrintingQueue", count, { count });
        },
        productDimensionsText(product) {
            return this.$t("OrderProductList.productDimensions", product.dimensions);
        },
        productPagesText(product) {
            return this.$t("OrderProductList.productPages", product.paging);
        },
    },
};
</script>

<style lang="scss" scoped>
    .order-product-thumbnail {
        height: 50px;
        width: auto;
    }

    .order-product-quantity {
        font-size: 1.5rem;
    }

    .product-title {
        font-size: 1rem;
    }
</style>