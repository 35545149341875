<template>
    <div id="printing-queue-modal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="$t('PrintingQueueModal.printingQueue')"/>
                    <div class="row mt-2">
                        <div v-if="robotStatus.isSmallBookBoxFull" class="col-12">
                            <div class="alert alert-soft-danger mt-2 w-100">
                                <i class="fa fa-exclamation-triangle mr-2"/>
                                <span v-html="$t('PrintingQueueModal.smallBookBoxFull')"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div v-if="robotStatus.isLargeBookBoxFull" class="col-12">
                            <div class="alert alert-soft-danger mt-2 w-100">
                                <i class="fa fa-exclamation-triangle mr-2"/>
                                <span v-html="$t('PrintingQueueModal.largeBookBoxFull')"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-pills nav-fill">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: panel === 'printing-queue' }" data-toggle="tab" href="#printing-queue"
                               @click.prevent="openPrintingQueueTab">
                                <i class="fa fa-print mr-2"/>
                                <span v-html="$t('PrintingQueueModal.printingQueue')"/>
                                <span class="badge badge-light ml-2" v-html="printingQueueJobs.length"/>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: panel === 'errored-queue' }" data-toggle="tab" href="#errored-queue"
                               @click.prevent="openErroredQueueTab">
                                <i class="fa fa-exclamation-circle mr-2"/>
                                <span v-html="$t('PrintingQueueModal.erroredQueue')"/>
                                <span class="badge badge-light ml-2" v-html="erroredJobs.length"/>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="printing-queue" class="tab-pane" :class="{ 'show active': panel === 'printing-queue' }" role="tabpanel">
                            <PrintingQueue class="mt-3" @show-order-print-modal="showOrderPrintModal"/>
                        </div>
                        <div id="errored-queue" class="tab-pane" :class="{ 'show active': panel === 'errored-queue' }" role="tabpanel">
                            <ErroredQueue class="mt-3" @show-order-print-modal="showOrderPrintModal"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-html="$t('OrderPaymentModal.close')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import PrintingQueue from "@/components/shared/Orders/Print/PrintingQueueModal/PrintingQueue/PrintingQueue";
import ErroredQueue from "@/components/shared/Orders/Print/PrintingQueueModal/ErroredQueue/ErroredQueue";

export default {
    name: "PrintingQueueModal",
    components: { ErroredQueue, PrintingQueue },
    data() {
        return { panel: "printing-queue" };
    },
    computed: {
        ...mapGetters("order", {
            printingQueueJobs: "printingQueueJobs",
            erroredJobs: "erroredJobs",
        }),
        ...mapGetters("robotStatus", { robotStatus: "robotStatus" }),
    },
    methods: {
        show() {
            $("#printing-queue-modal").modal("show");
        },
        hide() {
            $("#printing-queue-modal").modal("hide");
        },
        showOrderPrintModal(order) {
            this.hide();
            this.$emit("show-order-print-modal", order);
        },
        openPrintingQueueTab() {
            if (this.panel !== "printing-queue") {
                this.panel = "printing-queue";
            }
        },
        openErroredQueueTab() {
            if (this.panel !== "errored-queue") {
                this.panel = "errored-queue";
            }
        },
    },
};
</script>

<style scoped>

</style>