<template>
    <span v-if="order.comment" v-tooltip="order.comment" class="badge badge-pill badge-info cursor-pointer">
        <i class="fa fa-comment-alt mr-2"/>
        <span v-html="$t('OrderCommentBadge.comment')"/>
    </span>
</template>

<script>
import Order from "@/classes/Order";

export default {
    name: "OrderCommentBadge",
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>