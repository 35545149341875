<template>
    <div id="control-panel-alarms" class="row justify-content-center">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="text-primary">
                    <i class="fa fa-bell mr-2"/>
                    <span v-html="$t('ControlPanelAlarms.alarms')"/>
                </h3>
                <div>
                    <div v-if="robotErrors.length" class="alert alert-soft-danger mb-0">
                        <i class="fa fa-exclamation-triangle mr-2"/>
                        <span v-html="$t('ControlPanelAlarms.robotCantPrintIfErrors')"/>
                    </div>
                </div>
            </div>
            <hr class="my-2"/>
        </div>
        <div class="col-6">
            <a class="card card-hover-shadow alarm-card" href="#">
                <div class="card-body d-flex flex-column">
                    <h6 class="card-subtitle text-danger d-flex align-items-center">
                        <i class="fa fa-exclamation-circle mr-2"/>
                        <span v-html="$t('ControlPanelAlarms.errors')"/>
                        <span v-if="robotErrors.length" class="badge badge-danger ml-2" v-html="robotErrors.length"/>
                    </h6>
                    <div class="d-flex flex-grow-1 alarm-card-content">
                        <table v-if="robotErrors.length" class="table table-bordered table-striped">
                            <tbody>
                                <tr v-for="(error, index) of robotErrors" :key="index">
                                    <td class="text-center font-weight-bold" @click.prevent="showMoreContentOfError(error)" v-html="error"/>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="d-flex flex-grow-1 align-items-center justify-content-center">
                            <h2 class="text-muted text-center">
                                <i class="fa fa-check mr-2"/>
                                <span v-html="$t('ControlPanelAlarms.noError')"/>
                            </h2>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6">
            <a class="card card-hover-shadow alarm-card" href="#">
                <div class="card-body d-flex flex-column">
                    <h6 class="card-subtitle text-warning d-flex align-items-center">
                        <i class="fa fa-exclamation-triangle mr-2"/>
                        <span v-html="$t('ControlPanelAlarms.warnings')"/>
                        <span v-if="robotWarnings.length" class="badge badge-warning ml-2" v-html="robotWarnings.length"/>
                    </h6>
                    <div class="d-flex flex-grow-1 alarm-card-content">
                        <table v-if="robotWarnings.length" class="table table-bordered table-striped">
                            <tbody>
                                <tr v-for="(warning, index) of robotWarnings" :key="index">
                                    <td class="text-center font-weight-bold" @click.prevent="showMoreContentOfWarning(warning)" v-html="warning"/>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="d-flex flex-grow-1 align-items-center justify-content-center">
                            <h2 class="text-muted text-center">
                                <i class="fa fa-check mr-2"/>
                                <span v-html="$t('ControlPanelAlarms.noWarning')"/>
                            </h2>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-12 mt-2 text-center d-flex flex-column align-items-center">
            <SubmitButton :loading="loadings.acknowledgeAlarms" class="w-100" classes="btn btn-block btn-primary w-100"
                          @click.prevent="acknowledgeAlarms">
                <i class="fa fa-thumbs-up mr-2"/>
                <span v-html="$t('ControlPanelAlarms.acknowledgeAlarms')"/>
            </SubmitButton>
            <SubmitButton v-if="acknowledgeAlarmsRequestSent" :loading="loadings.acknowledgeAlarms" class="text-center"
                          classes="btn btn-secondary mt-2 text-center" @click.prevent="cancelAcknowledgeAlarmsRequest">
                <i class="fa fa-times-circle mr-2"/>
                <span v-html="$t('ControlPanelRobot.cancel')"/>
            </SubmitButton>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import Swal from "sweetalert2";

export default {
    name: "ControlPanelAlarms",
    components: { SubmitButton },
    data() {
        return { loadings: { acknowledgeAlarms: false } };
    },
    computed: {
        ...mapGetters("robotStatus", {
            robotErrors: "robotErrors",
            robotWarnings: "robotWarnings",
        }),
        ...mapGetters("robotStatus", {
            robotStatus: "robotStatus",
            acknowledgeAlarmsRequestSent: "acknowledgeAlarmsRequestSent",
        }),
    },
    watch: {
        "robotStatus.fault.acknowledged": {
            handler(value) {
                if (value && this.acknowledgeAlarmsRequestSent) {
                    this.cancelAcknowledgeAlarmsRequest();
                }
            },
            immediate: true,
        },
    },
    methods: {
        confirmAcknowledgeAlarms() {
            return Swal.fire({
                title: this.$t("ControlPanelAlarms.areYouSure"),
                text: this.$t("ControlPanelAlarms.youWillAcknowledgeAllAlarms"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                confirmButtonText: this.$t("ControlPanelAlarms.confirm"),
                cancelButtonText: this.$t("ControlPanelAlarms.cancel"),
            });
        },
        async acknowledgeAlarms() {
            try {
                const { isConfirmed } = await this.confirmAcknowledgeAlarms();
                if (isConfirmed) {
                    this.loadings.acknowledgeAlarms = true;
                    await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { acknowledgeAlarms: true } });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loadings.acknowledgeAlarms = false;
            }
        },
        async cancelAcknowledgeAlarmsRequest() {
            try {
                this.loadings.acknowledgeAlarms = true;
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { acknowledgeAlarms: false } });
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loadings.acknowledgeAlarms = false;
            }
        },
        showMoreContentOfError(error) {
            this.$toasted.error(error, { icon: "times" });
        },
        showMoreContentOfWarning(warning) {
            this.$toasted.error(warning, { icon: "exclamation-triangle" });
        },
    },
};
</script>

<style lang="scss" scoped>
    .alarm-card {
        height: 250px;

        .alarm-card-content {
            overflow-y: auto;
        }
    }
</style>