export function getSecondsDelayFromNow(date) {
    return Math.abs(new Date().getTime() - new Date(date).getTime()) / 1000;
}

export function formatDate(d) {
    const date = new Date(d);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export function formatTime(d) {
    if (Number.isInteger(d)) {
        d *= 1000;
    }
    const date = new Date(d);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
}