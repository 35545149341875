import { getProp } from "@/helpers/functions/Class";

class Job {
    // eslint-disable-next-line max-lines-per-function
    constructor(job = null) {
        this._id = getProp(job, "_id");
        this.orderId = getProp(job, "orderId");
        this.productId = getProp(job, "productId");
        this.order = { reference: getProp(job, "order.reference") };
        this.product = {
            title: getProp(job, "product.title"),
            ISBN: getProp(job, "product.ISBN"),
            photosURL: { cover: { first: getProp(job, "product.photosURL.cover.first") } },
        };
        this.status = getProp(job, "status");
        this.printNumber = getProp(job, "printNumber");
        this.errorType = getProp(job, "errorType");
        this.currentStep = getProp(job, "currentStep");
        this.times = {
            cover: {
                printing: {
                    startedAt: getProp(job, "times.cover.printing.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.cover.printing.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
            },
            corpus: {
                printing: {
                    startedAt: getProp(job, "times.corpus.printing.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.corpus.printing.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
                crushing: {
                    startedAt: getProp(job, "times.corpus.crushing.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.corpus.crushing.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
                depositing: {
                    startedAt: getProp(job, "times.corpus.depositing.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.corpus.depositing.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
            },
            shaping: {
                assembling: {
                    startedAt: getProp(job, "times.shaping.assembling.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.shaping.assembling.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
                cutting: {
                    startedAt: getProp(job, "times.shaping.cutting.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.shaping.cutting.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
                finishing: {
                    startedAt: getProp(job, "times.shaping.finishing.startedAt", undefined, startedAt => new Date(startedAt)),
                    finishedAt: getProp(job, "times.shaping.finishing.finishedAt", undefined, finishedAt => new Date(finishedAt)),
                },
            },
        };
        this.review = {
            isWellDone: getProp(job, "review.isWellDone"),
            cover: {
                printer: {
                    didntPrint: getProp(job, "review.cover.printer.didntPrint"),
                    productionStoppedAfterPrinting: getProp(job, "review.cover.printer.productionStoppedAfterPrinting"),
                },
                didntFitInCreasingMachine: getProp(job, "review.cover.didntFitInCreasingMachine"),
                staggeredFromCorpus: getProp(job, "review.cover.staggeredFromCorpus"),
                deformedOnBookSpine: getProp(job, "review.cover.deformedOnBookSpine"),
                badlyCut: getProp(job, "review.cover.badlyCut"),
            },
            corpus: {
                file: { textsMisaligned: getProp(job, "review.corpus.file.textsMisaligned") },
                printer: {
                    didntPrint: getProp(job, "review.corpus.printer.didntPrint"),
                    productionStoppedAfterPrinting: getProp(job, "review.corpus.printer.productionStoppedAfterPrinting"),
                },
                transferring: {
                    didntFitInCrushingStation: getProp(job, "review.corpus.transferring.didntFitInCrushingStation"),
                    pagesDisturbed: getProp(job, "review.corpus.transferring.pagesDisturbed"),
                },
                assembling: {
                    didntFitInCutter: getProp(job, "review.corpus.assembling.didntFitInCutter"),
                    didntFitInCrushingStation: getProp(job, "review.corpus.assembling.didntFitInCrushingStation"),
                    didntFitInBindingStation: getProp(job, "review.corpus.assembling.didntFitInBindingStation"),
                    pagesDisturbed: getProp(job, "review.corpus.assembling.pagesDisturbed"),
                    cannotBeOverlaid: getProp(job, "review.corpus.assembling.cannotBeOverlaid"),
                },
            },
            shaping: {
                gluing: {
                    gun: {
                        clogged: getProp(job, "review.shaping.gluing.gun.clogged"),
                        didntStoreItselfWell: getProp(job, "review.shaping.gluing.gun.didntStoreItselfWell"),
                    },
                    wasBadlyApplied: getProp(job, "review.shaping.gluing.wasBadlyApplied"),
                },
                assembling: {
                    didntFitInCrushingStation: getProp(job, "review.shaping.assembling.didntFitInCrushingStation"),
                    clampDidntTake: getProp(job, "review.shaping.assembling.clampDidntTake"),
                    loosingPages: getProp(job, "review.shaping.assembling.loosingPages"),
                },
                cutting: { didntFitInCutter: getProp(job, "review.shaping.cutting.didntFitInCutter") },
            },
        };
        this.createdAt = getProp(job, "createdAt", undefined, createdAt => new Date(createdAt));
        this.updatedAt = getProp(job, "updatedAt", undefined, updatedAt => new Date(updatedAt));
    }

    static get availableSteps() {
        return [
            "pending",
            "printing-cover-and-corpus",
            "waiting-for-transferring-corpus",
            "transferring-corpus",
            "crushing-corpus",
            "placing-corpus",
            "taking-cover",
            "assembling",
            "cutting",
            "finishing",
            "done",
        ];
    }

    get isInPrintingQueue() {
        return this.status === "pending" || this.status === "processing";
    }
}

export default Job;