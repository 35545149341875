<template>
    <div id="product-files-status">
        <div class="d-flex justify-content-center align-items-center">
            <div class="badge" :class="`badge-${productFilesStatusColor}`">
                <i class="fa mr-2" :class="productFilesStatusIcon"/>
                <span v-html="productFilesStatusText"/>
            </div>
            <SubmitButton v-if="isDownloadFilesButtonDisplayed" id="download-files-button" :loading="loadings.downloadFiles" class="ml-2"
                          classes="btn btn-danger btn-xs" @click="downloadFiles">
                <i class="fa fa-sync"/>
            </SubmitButton>
        </div>
        <div v-if="product.files.status === 'downloading'" class="progress mt-2">
            <div class="progress-bar progress-bar-striped" role="progressbar" :style="{ width: `${downloadProductFilesPercent}%` }"/>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Product from "@/classes/Product";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import Order from "@/classes/Order";

export default {
    name: "ProductFilesStatus",
    components: { SubmitButton },
    props: {
        order: {
            type: Order,
            required: true,
        },
        product: {
            type: Product,
            required: true,
        },
    },
    data() {
        return { loadings: { downloadFiles: false } };
    },
    computed: {
        productFilesStatuses() {
            return {
                pending: {
                    icon: "fa-clock",
                    color: "warning",
                    text: this.$t("ProductFilesStatus.status.pending"),
                },
                downloading: {
                    icon: "fa-file-download",
                    color: "info",
                    text: this.$t("ProductFilesStatus.status.downloading"),
                },
                errored: {
                    icon: "fa-times",
                    color: "danger",
                    text: this.$t("ProductFilesStatus.status.errored"),
                },
                unzipping: {
                    icon: "fa-file-archive",
                    color: "info",
                    text: this.$t("ProductFilesStatus.status.unzipping"),
                },
                printable: {
                    icon: "fa-check-circle",
                    color: "success",
                    text: this.$t("ProductFilesStatus.status.printable"),
                },
            };
        },
        productFilesStatusIcon() {
            const productFilesStatus = this.product.files.status;
            return this.productFilesStatuses[productFilesStatus] ? this.productFilesStatuses[productFilesStatus].icon : "fa-question-circle";
        },
        productFilesStatusColor() {
            const productFilesStatus = this.product.files.status;
            return this.productFilesStatuses[productFilesStatus] ? this.productFilesStatuses[productFilesStatus].color : "secondary";
        },
        productFilesStatusText() {
            const productFilesStatus = this.product.files.status;
            if (this.productFilesStatuses[productFilesStatus]) {
                return this.productFilesStatuses[productFilesStatus].text;
            }
            return this.$t("ProductFilesStatus.status.unknown");
        },
        downloadProductFilesPercent() {
            return Math.round(this.product.files.download.percent);
        },
        isDownloadFilesButtonDisplayed() {
            const productFilesStatus = this.product.files.status;
            return (productFilesStatus === "canceled" || productFilesStatus === "printable") &&
                !this.order.isInPrintingQueue && !this.order.isPrinting;
        },
    },
    methods: {
        confirmDownloadFiles() {
            return Swal.fire({
                title: this.$t("ProductFilesStatus.areYouSure"),
                text: this.$t("ProductFilesStatus.youWillDownloadProductFiles"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                confirmButtonText: this.$t("ProductFilesStatus.confirm"),
                cancelButtonText: this.$t("ProductFilesStatus.cancel"),
            });
        },
        async downloadFiles() {
            try {
                const { isConfirmed } = await this.confirmDownloadFiles();
                if (isConfirmed) {
                    this.loadings.downloadFiles = true;
                    await this.$gutenbergOneRobotAPI.patchOrderProduct(this.order._id, this.product._id, { files: { status: "pending" } });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loadings.downloadFiles = false;
            }
        },
    },
};
</script>

<style scoped>
    #download-files-button {
        width: 20px;
        height: 20px;
    }
</style>