export const robotAlarmErrorNames = [
    "MODULE_11_IS_MISSING",
    "MODULE_12_IS_MISSING",
    "MODULE_21_IS_MISSING",
    "MODULE_25_IS_MISSING",
    "MODULE_70_IS_MISSING",
    "MODULE_71_IS_MISSING",
    "MODULE_80_IS_MISSING",
    "MODULE_100_IS_MISSING",
    "MODULE_211_IS_MISSING",
    "MODULE_271_IS_MISSING",
    "MODULE_272_IS_MISSING",
    "GLOBAL_EMERGENCY_STOP_TRIGGERED",
    "GLUE_GUN_DOOR_OPENED",
    "CUTTER_DOOR_OPENED",
    "BINDER_DOOR_OPENED",
    "SHUFFLER_DOOR_OPENED",
    "CORPUS_PRINTER_NOT_IN_PLACE",
    "CUTTER_HATCH_NOT_IN_PLACE",
    "UNKNOWN_1_18",
    "UNKNOWN_1_19",
    "TEMPERATURE_TOO_HIGH_FOR_BINDER",
    "UNKNOWN_1_21",
    "UNKNOWN_1_22",
    "UNKNOWN_1_23",
    "UNKNOWN_1_24",
    "ERROR_ROBOT_CHECK_LOGS",
    "GLUE_IS_EMPTY",
    "GLUE_STUCK_INTO_GLUE_GUN",
    "COVER_MISSING_DURING_GROOVING_PROCESS",
    "COVER_MISSING_DURING_GLUING_PROCESS",
    "COVER_MISSING_DURING_GRIPPING_PROCESS",
    "ERROR_GRIPPER_CHECK_LOGS",
    "ERROR_STEPPER_MOTOR_CHECK_LOGS",
    "GRIPPER_CANT_CLOSE_ON_RIGHT_THICKNESS",
    "CANT_SHUFFLE_CORPUS_RIGHT",
    "INCORRECT_JOB_DATA",
    "BINDER_HEATING_HAS_BEEN_ON_TOO_LONG",
    "ROBOT_UNABLE_FLUSH_A5_CORPUS_COLLECTING_TRAY",
    "COVER_IS_STUCK_BEFORE_GLUING",
    "COVER_IS_STUCK_BEFORE_REHEATING",
    "UNKNOWN_2_08",
    "UNKNOWN_2_09",
    "UNKNOWN_2_10",
    "UNKNOWN_2_11",
    "UNKNOWN_2_12",
    "UNKNOWN_2_13",
    "UNKNOWN_2_14",
    "UNKNOWN_2_15",
    "UNKNOWN_2_16",
    "UNKNOWN_2_17",
    "PAPER_CUTTER_SIDE_CLAMP_NOT_COMPLETELY_OUT",
    "PAPER_CUTTER_SIDE_CLAMP_NOT_COMPLETELY_IN",
    "COLLECTOR_NOT_COMPLETELY_OUT",
    "COLLECTOR_NOT_COMPLETELY_IN",
    "HEATING_ELEMENT_NOT_COMPLETELY_DOWN",
    "HEATING_ELEMENT_NOT_COMPLETELY_UP",
    "BINDER_SIDE_CLAMP_NOT_COMPLETELY_OUT",
    "BINDER_SIDE_CLAMP_NOT_COMPLETELY_IN",
    "BINDER_BACK_JAW_NOT_COMPLETELY_OUT",
    "BINDER_BACK_JAW_NOT_COMPLETELY_IN",
    "BINDER_FRONT_JAW_NOT_COMPLETELY_OUT",
    "BINDER_FRONT_JAW_NOT_COMPLETELY_IN",
    "COLLECTOR_NOT_COMPLETELY_TILTED",
    "COLLECTOR_NOT_COMPLETELY_RAISED",
    "GROOVER_NOT_COMPLETELY_OUT",
    "GROOVER_NOT_COMPLETELY_IN",
    "GREEKING_SIDE_CLAMP_NOT_COMPLETELY_OUT",
    "GREEKING_SIDE_CLAMP_NOT_COMPLETELY_IN",
    "GREEKING_NOT_COMPLETELY_OUT",
    "GREEKING_NOT_COMPLETELY_IN",
    "COLLECTING_TRAY_NOT_COMPLETELY_TILTED",
    "COLLECTING_TRAY_NOT_COMPLETELY_RAISED",
    "COLLECTING_TRAY_PUSHER_NOT_COMPLETELY_OUT",
    "COLLECTING_TRAY_PUSHER_NOT_COMPLETELY_IN",
    "GLUE_HEATER_FINS_ARE_FAULTY",
    "UNKNOWN_3_11",
    "UNKNOWN_3_12",
    "UNKNOWN_3_13",
    "UNKNOWN_3_14",
    "UNKNOWN_3_15",
    "TOO_LOW_PRESSURE_CHECK_SUPPLY",
    "UNKNOWN_3_17",
    "UNKNOWN_3_18",
    "UNKNOWN_3_19",
    "UNKNOWN_3_20",
    "UNKNOWN_3_21",
    "UNKNOWN_3_22",
    "UNKNOWN_3_23",
    "UNKNOWN_3_24",
    "UNKNOWN_3_25",
    "UNKNOWN_3_26",
    "UNKNOWN_3_27",
    "UNKNOWN_3_28",
    "UNKNOWN_3_29",
    "UNKNOWN_3_30",
    "UNKNOWN_3_31",
];

export const robotAlarmWarningNames = [
    "GLUE_ALMOST_EMPTY",
    "ROBOT_NOT_INITIALISED",
    "COVER_IS_INTO_BINDER_AT_START_CYCLE",
    "ERROR_GLUE_CALCULATION_PROGRAM",
    "BOOK_VALUES_ARE_INCORRECT_OR_OUT",
    "CORPUS_IS_INTO_COLLECTING_TRAY_AT_START_CYCLE",
    "UNKNOWN_8_06",
    "UNKNOWN_8_07",
    "UNKNOWN_8_08",
    "UNKNOWN_8_09",
    "UNKNOWN_8_10",
    "UNKNOWN_8_11",
    "UNKNOWN_8_12",
    "UNKNOWN_8_13",
    "UNKNOWN_8_14",
    "UNKNOWN_8_15",
    "UNKNOWN_8_16",
    "UNKNOWN_8_17",
    "UNKNOWN_8_18",
    "UNKNOWN_8_19",
    "UNKNOWN_8_20",
    "UNKNOWN_8_21",
    "UNKNOWN_8_22",
    "UNKNOWN_8_23",
    "UNKNOWN_8_24",
    "UNKNOWN_8_25",
    "UNKNOWN_8_26",
    "UNKNOWN_8_27",
    "UNKNOWN_8_28",
    "UNKNOWN_8_29",
    "UNKNOWN_8_30",
    "UNKNOWN_8_31",
];