import Vue from "vue";
import { ClientTable } from "vue-tables-2";

const options = {
    sortIcon: {
        base: "fa",
        up: "fa-chevron-up",
        down: "fa-chevron-down",
        is: "fa-sort",
    },
};
Vue.use(ClientTable, options, false, "bootstrap4");