<template>
    <img v-lazy="product.photosURL.cover.first" alt="Role Image" class="img-fluid rounded"/>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "ProductThumbnail",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>