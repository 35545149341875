import Vue from "vue";
import RobotStatus from "@/classes/RobotStatus";

export default {
    namespaced: true,
    state: { robotStatus: new RobotStatus() },
    getters: {
        robotStatus(state) {
            return state.robotStatus;
        },
        robotMode(state) {
            return state.robotStatus.mode;
        },
        robotState(state) {
            return state.robotStatus.state;
        },
        robotCurrentJob(state) {
            return state.robotStatus.currentJob;
        },
        robotBookBoxes(state) {
            return state.robotStatus.bookBoxes;
        },
        startRobotRequestSent(state) {
            return state.robotStatus.requestFromOperator.start;
        },
        stopRobotRequestSent(state) {
            return state.robotStatus.requestFromOperator.stop;
        },
        unlockRobotBookBoxesDoorRequestSent(state) {
            return state.robotStatus.requestFromOperator.unlockBookBoxes;
        },
        acknowledgeAlarmsRequestSent(state) {
            return state.robotStatus.requestFromOperator.acknowledgeAlarms;
        },
        robotCorpusPrinter(state) {
            return state.robotStatus.printers.corpus;
        },
        robotCoverPrinter(state) {
            return state.robotStatus.printers.cover;
        },
        robotTimestamps(state) {
            return state.robotStatus.timestamps;
        },
        robotErrors(state) {
            return state.robotStatus.errors;
        },
        robotWarnings(state) {
            return state.robotStatus.warnings;
        },
    },
    mutations: {
        setRobotStatus(state, robotStatus) {
            state.robotStatus = new RobotStatus(robotStatus);
        },
    },
    actions: {
        setRobotStatusLongPolling({ dispatch }) {
            setInterval(() => dispatch("getAndSetRobotStatus"), 1000);
        },
        async getAndSetRobotStatus({ commit }) {
            const { data } = await Vue.prototype.$gutenbergOneRobotAPI.getRobotStatus();
            commit("setRobotStatus", data);
        },
        setRobotStatus({ commit }, robotStatus) {
            commit("setRobotStatus", robotStatus);
        },
    },
};