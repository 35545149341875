import Axios from "axios";
import { stringify } from "qs";
import Config from "../../../config";

const GutenbergOneRobotAPI = {
    // eslint-disable-next-line no-unused-vars,max-lines-per-function
    install(Vue) {
        const axiosConfig = {
            baseURL: Config.API.gutenbergOneRobot.baseURL,
            auth: Config.API.gutenbergOneRobot.basicAuth,
            timeout: 0,
            headers: { "Content-Type": "application/json" },
        };
        const axios = Axios.create(axiosConfig);

        Vue.prototype.$gutenbergOneRobotAPI = {};

        Vue.prototype.$gutenbergOneRobotAPI.getOrders = queryString => axios.get(`/orders?${stringify(queryString)}`);

        Vue.prototype.$gutenbergOneRobotAPI.getOrder = orderId => axios.get(`/orders/${orderId}`);

        Vue.prototype.$gutenbergOneRobotAPI.patchOrder = (orderId, data) => axios.patch(`/orders/${orderId}`, data);

        Vue.prototype.$gutenbergOneRobotAPI.patchOrderProduct = (orderId, productId, data) => {
            const URL = `/orders/${orderId}/products/${productId}`;
            return axios.patch(URL, data);
        };

        Vue.prototype.$gutenbergOneRobotAPI.addJobToProduct = (orderId, productId) => axios.post(`/orders/${orderId}/products/${productId}/jobs`);

        Vue.prototype.$gutenbergOneRobotAPI.patchJob = (orderId, productId, jobId, data) => {
            const URL = `/orders/${orderId}/products/${productId}/jobs/${jobId}`;
            return axios.patch(URL, data);
        };

        Vue.prototype.$gutenbergOneRobotAPI.getRobotStatus = queryString => axios.get(`/robot-status?${stringify(queryString)}`);

        Vue.prototype.$gutenbergOneRobotAPI.updateRobotStatus = data => axios.patch(`/robot-status`, data);

        Vue.prototype.$gutenbergOneRobotAPI.getHistoryPrintedOrder = data => axios.get(`/jobs/printed`, data);
    },
};

export default GutenbergOneRobotAPI;