<template>
    <button v-if="order.canPrint" class="btn btn-outline-success btn-sm btn-block"
            @click.prevent="$emit('show-order-print-modal', order)">
        <i class="fa fa-print mr-2"/>
        <span v-html="$t('OrderPrintButton.printOrder')"/>
    </button>
</template>

<script>
import Order from "@/classes/Order";

export default {
    name: "OrderPrintButton",
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>