<template>
    <div class="content container-fluid">
        <PageHeader>
            <i class="fa fa-box-open mr-2"/>
            <span v-html="$t('Orders.orders')"/>
        </PageHeader>
        <div class="card">
            <v-client-table :data="orders" :columns="ordersTable.columns" :options="ordersTable.options">
                <div slot="reference" slot-scope="props">
                    <div>
                        <i class="fa fa-box text-primary mr-2"/>
                        <span class="font-weight-bolder" v-html="props.row.reference"/>
                    </div>
                    <OrderCommentBadge :order="props.row"/>
                    <hr class="m-2"/>
                    <div class="mt-1">
                        <i class="fa fa-user text-warning mr-2"/>
                        <span v-html="props.row.client"/>
                    </div>
                    <OrderCommentBadge :order="props.row"/>
                    <hr class="m-2"/>
                    <div v-if="props.row.priority" class="mt-1">
                        <i class="fa fa-fighter-jet text-info mr-2"/>
                        <span v-html="$t('Orders.priority')"/>
                    </div>
                </div>
                <ul slot="products" slot-scope="props" class="mb-0">
                    <li v-for="product of props.row.products" :key="product._id"
                        v-html="`${product.ISBN} - ${product.title} (${product.paging.total} p.) x ${product.quantity}`"/>
                </ul>
                <div slot="status" slot-scope="props">
                    <OrderPaymentStatus v-if="showPrice === true" :order="props.row" class="mb-1"/>
                    <OrderProductionStatus :order="props.row"/>
                </div>
                <div slot="orderedAt" slot-scope="props">
                    <div>
                        <i class="fa fa-calendar text-primary mr-2"/>
                        <span v-html="formatDate(props.row.orderedAt)"/>
                    </div>
                    <div class="mt-1">
                        <i class="fa fa-clock text-primary mr-2"/>
                        <span v-html="formatTime(props.row.orderedAt)"/>
                    </div>
                </div>
                <div slot="actions" slot-scope="props">
                    <OrderPaymentButton v-if="showPrice === true" class="mb-2" :order="props.row" @show-order-payment-modal="showOrderPaymentModal"/>
                    <OrderPrintButton class="mb-2" :order="props.row" @show-order-print-modal="showOrderPrintModal"/>
                    <OrderCancelButton class="mb-2" :order="props.row"/>
                    <OrderArchiveButton v-if="showPrice === true" :order="props.row"/>
                </div>
            </v-client-table>
        </div>
        <OrderPaymentModal ref="orderPaymentModal"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/shared/Misc/PageHeader/PageHeader";
import OrderPaymentStatus from "@/components/shared/Orders/Status/OrderPaymentStatus";
import OrderProductionStatus from "@/components/shared/Orders/Status/OrderProductionStatus";
import { formatDate, formatTime } from "@/helpers/functions/Date";
import OrderPaymentModal from "@/components/shared/Orders/Payment/OrderPaymentModal";
import OrderCancelButton from "@/components/shared/Orders/OrderCancelButton";
import OrderPaymentButton from "@/components/shared/Orders/Payment/OrderPaymentButton";
import OrderPrintButton from "@/components/shared/Orders/Print/OrderPrint/OrderPrintButton";
import OrderArchiveButton from "@/components/shared/Orders/OrderArchiveButton";
import OrderCommentBadge from "@/components/shared/Orders/OrderCommentBadge";
import Config from "../../../config";

export default {
    name: "Orders",
    components: {
        OrderCommentBadge,
        OrderArchiveButton,
        OrderPrintButton,
        OrderPaymentButton,
        OrderCancelButton,
        OrderPaymentModal,
        OrderProductionStatus,
        OrderPaymentStatus,
        PageHeader,
    },
    // eslint-disable-next-line max-lines-per-function
    data() {
        return {
            showPrice: Config.app.showPrice,
            ordersTable: {
                columns: ["reference", "products", "status", "orderedAt", "actions"],
                options: {
                    headings: {
                        reference: this.$t("Orders.referenceAndClient"),
                        products: this.$t("Orders.content"),
                        status: this.$t("Orders.status"),
                        orderedAt: "Commandée",
                        actions: "Actions",
                    },
                    dateColumns: ["orderedAt"],
                    filterable: ["reference", "client", "products", "orderedAt"],
                    orderBy: {
                        column: "orderedAt",
                        ascending: false,
                    },
                    perPage: 5,
                    perPageValues: [5, 10, 25, 50, 100],
                    sortable: ["orderedAt"],
                    columnsClasses: {
                        reference: "align-middle",
                        products: "align-middle",
                        status: "text-center align-middle",
                        orderedAt: "text-center align-middle",
                        actions: "text-center align-middle",
                    },
                },
            },
        };
    },
    computed: { ...mapGetters("order", { orders: "orders" }) },
    methods: {
        formatDate,
        formatTime,
        showOrderPaymentModal(order) {
            this.$refs.orderPaymentModal.show(order);
        },
        showOrderPrintModal(order) {
            this.$emit("show-order-print-modal", order);
        },
    },
};
</script>

<style lang="scss" scoped>

.order-product-quantity {
    font-size: 1.2rem;
}

</style>