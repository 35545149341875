<template>
    <div id="control-panel-robot" class="row justify-content-center align-items-start">
        <div class="col-12">
            <h3 class="text-primary">
                <i class="fa fa-robot mr-2"/>
                <span v-html="$t('ControlPanelRobot.robot')"/>
            </h3>
            <hr/>
        </div>
        <div class="col-12">
            <a class="card card-hover-shadow h-100" href="#">
                <div class="card-body">
                    <h6 class="card-subtitle text-primary d-inline" v-html="$t('ControlPanelRobot.mode.label')"/>
                    <span class="badge badge-soft-secondary ml-2" v-html="$t('ControlPanelRobot.code', { code: robotMode })"/>
                    <div class="row align-items-center gx-2 mb-1">
                        <div class="col-12">
                            <span class="card-title h2" v-html="$t(`ControlPanelRobot.mode.${robotMode}`)"/>
                        </div>
                        <div v-if="robotMode !== 20" class="col-12">
                            <div class="alert alert-soft-danger mt-2 w-100">
                                <i class="fa fa-exclamation-triangle mr-2"/>
                                <span v-html="$t('ControlPanelRobot.robotCantPrintOnThisMode')"/>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2"/>
                    <SubmitButton v-if="!robotStatus.isInProducingMode" :loading="loading.startRobot"
                                  classes="btn btn-block text-uppercase btn-success" :disabled="startRobotRequestSent" @click.prevent="startRobot">
                        <i class="fa fa-play-circle mr-2"/>
                        <span v-html="startRobotButtonText"/>
                    </SubmitButton>
                    <div v-if="startRobotRequestSent" class="d-flex justify-content-center">
                        <SubmitButton :loading="loading.startRobot" class="text-center"
                                      classes="btn btn-secondary mt-2 text-center" @click.prevent="cancelStartRobotRequest">
                            <i class="fa fa-times-circle mr-2"/>
                            <span v-html="$t('ControlPanelRobot.cancel')"/>
                        </SubmitButton>
                    </div>
                    <SubmitButton :loading="loading.stopRobot" classes="btn btn-block text-uppercase btn-danger mt-2" :disabled="stopRobotRequestSent"
                                  @click.prevent="stopRobot">
                        <i class="fa fa-stop-circle mr-2"/>
                        <span v-html="stopRobotButtonText"/>
                    </SubmitButton>
                    <div v-if="stopRobotRequestSent" class="d-flex justify-content-center">
                        <SubmitButton :loading="loading.stopRobot" class="text-center"
                                      classes="btn btn-secondary mt-2 text-center" @click.prevent="cancelStopRobotRequest">
                            <i class="fa fa-times-circle mr-2"/>
                            <span v-html="$t('ControlPanelRobot.cancel')"/>
                        </SubmitButton>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-12">
            <a class="card card-hover-shadow h-100" href="#">
                <div class="card-body d-flex flex-column">
                    <div>
                        <h6 class="card-subtitle text-primary d-inline" v-html="$t('ControlPanelRobot.state.label')"/>
                        <span class="badge badge-soft-secondary ml-2" v-html="$t('ControlPanelRobot.code', { code: robotState })"/>
                    </div>
                    <div class="row align-items-center gx-2 mb-1">
                        <div class="col-12">
                            <span class="card-title h2" v-html="$t(`ControlPanelRobot.state.${robotState}`)"/>
                        </div>
                    </div>
                    <hr class="my-2"/>
                    <div class="row text-dark d-flex flex-grow-1 align-items-center">
                        <div class="col-6 text-center">
                            <div class="text-primary">
                                <i class="fa fa-code mr-2"/>
                                <span v-html="$t('ControlPanelRobot.lastSignalFromSoBook')"/>
                            </div>
                            <i class="fa fa-signal mr-2" :class="signalFromSoBookClass"/>
                            <span v-html="formatTime(new Date(robotTimestamps.fromSoBook * 1000))"/>
                        </div>
                        <div class="col-6 text-center">
                            <div class="text-primary">
                                <i class="fa fa-robot mr-2"/>
                                <span v-html="$t('ControlPanelRobot.lastSignalFromRobot')"/>
                            </div>
                            <i class="fa fa-signal mr-2" :class="signalFromRobotClass"/>
                            <span v-html="formatTime(new Date(robotTimestamps.fromRobot * 1000))"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import { formatTime, getSecondsDelayFromNow } from "@/helpers/functions/Date";

export default {
    name: "ControlPanelRobot",
    components: { SubmitButton },
    data() {
        return {
            loading: {
                startRobot: false,
                stopRobot: false,
            },
        };
    },
    computed: {
        ...mapGetters("robotStatus", {
            robotStatus: "robotStatus",
            robotMode: "robotMode",
            robotState: "robotState",
            startRobotRequestSent: "startRobotRequestSent",
            stopRobotRequestSent: "stopRobotRequestSent",
            robotTimestamps: "robotTimestamps",
        }),
        startRobotButtonText() {
            return this.startRobotRequestSent ? this.$t("ControlPanelRobot.startRobotRequestSent") : this.$t("ControlPanelRobot.startRobot");
        },
        stopRobotButtonText() {
            return this.stopRobotRequestSent ? this.$t("ControlPanelRobot.stopRobotRequestSent") : this.$t("ControlPanelRobot.stopRobot");
        },
        signalFromSoBookClass() {
            return getSecondsDelayFromNow(this.robotTimestamps.fromSoBook * 1000) > 30 ? "text-danger" : "text-success";
        },
        signalFromRobotClass() {
            return getSecondsDelayFromNow(this.robotTimestamps.fromRobot * 1000) > 30 ? "text-danger" : "text-success";
        },
    },
    watch: {
        "robotStatus.isInProducingMode": {
            handler(value) {
                if (value && this.startRobotRequestSent) {
                    this.cancelStartRobotRequest();
                }
            },
            immediate: true,
        },
        "robotStatus.isInIdleMode": {
            handler(value) {
                if (value && this.stopRobotRequestSent) {
                    this.cancelStopRobotRequest();
                }
            },
            immediate: true,
        },
    },
    methods: {
        confirmStartRobot() {
            return Swal.fire({
                title: this.$t("ControlPanelRobot.areYouSure"),
                text: this.$t("ControlPanelRobot.youWillStartRobot"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                confirmButtonText: this.$t("ControlPanelRobot.confirm"),
                cancelButtonText: this.$t("ControlPanelRobot.cancel"),
            });
        },
        async startRobot() {
            try {
                const { isConfirmed } = await this.confirmStartRobot();
                if (isConfirmed) {
                    this.loading.startRobot = true;
                    await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { start: true } });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading.startRobot = false;
            }
        },
        async cancelStartRobotRequest() {
            try {
                this.loading.startRobot = true;
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { start: false } });
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading.startRobot = false;
            }
        },
        async stopRobot() {
            try {
                this.loading.stopRobot = true;
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { stop: true } });
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading.stopRobot = false;
            }
        },
        async cancelStopRobotRequest() {
            try {
                this.loading.stopRobot = true;
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { stop: false } });
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading.stopRobot = false;
            }
        },
        formatTime,
    },
};
</script>

<style lang="scss" scoped>
    #control-panel-robot-actions-panel {
        border: 1px lightgray solid;
        border-radius: 10px;
    }
</style>