<template>
    <div id="order-payment-modal" class="modal" tabindex="-1" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
            <div v-if="order._id" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="$t('OrderPaymentModal.payOrderOfClient', order)"/>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <div class="row">
                            <div class="col-12" v-html="$t('OrderPaymentModal.theClientHasToPay', order)"/>
                        </div>
                        <div class="row mt-1 mb-2">
                            <div id="order-total" class="col-12 text-primary"
                                 v-html="`${formatPrice(order.totalWT)} ${$t('OrderPaymentModal.withTaxes')}`"/>
                        </div>
                        <div class="row">
                            <div class="col-12" v-html="$t('OrderPaymentModal.forTheOrderContainingProducts', order)"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <OrderProductList :order="order"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <SubmitButton classes="btn btn-primary" :loading="loading" @click="payOrder">
                        <i class="fa fa-euro-sign mr-2"/>
                        <span v-html="$t('OrderPaymentModal.tagAsPayed')"/>
                    </SubmitButton>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="loading"
                            v-html="$t('OrderPaymentModal.close')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import $ from "jquery";
import Order from "@/classes/Order";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import { formatPrice } from "@/helpers/functions/Price";
import OrderProductList from "@/components/shared/Orders/Products/OrderProductList";

export default {
    name: "OrderPaymentModal",
    components: { OrderProductList, SubmitButton },
    data() {
        return {
            order: new Order(),
            loading: false,
        };
    },
    methods: {
        ...mapActions("order", { updateOrder: "updateOrder" }),
        formatPrice,
        show(order) {
            this.order = new Order(order);
            $("#order-payment-modal").modal("show");
        },
        hide() {
            $("#order-payment-modal").modal("hide");
        },
        confirmPayOrder() {
            return Swal.fire({
                title: this.$t("OrderPaymentModal.areYouSure"),
                text: this.$t("OrderPaymentModal.payOrderIsIrreversible"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("OrderPaymentModal.cancel"),
            });
        },
        async payOrder() {
            try {
                const { isConfirmed } = await this.confirmPayOrder();
                if (isConfirmed) {
                    this.loading = true;
                    const { data } = await this.$gutenbergOneRobotAPI.patchOrder(this.order._id, { status: { payment: "accepted" } });
                    this.updateOrder(data);
                    this.hide();
                    this.$toasted.success(this.$t("OrderPaymentModal.paymentAccepted"), { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #order-total {
        font-size: 2rem;
    }
</style>