<template>
    <div class="control-panel-printer">
        <a class="card card-hover-shadow h-100" href="#">
            <div class="card-body text-dark">
                <i class="fa mr-2" :class="printerAvailabilityClasses"/>
                <h6 class="card-subtitle text-primary d-inline" v-html="printerTitle"/>
                <span class="ml-2 badge" :class="printerIsEmptyClass" v-html="printerIsEmptyText"/>
                <span v-if="!!printingOrder" class="ml-2 badge" :class="printerHasPrintedClass">
                    <i class="mr-2" :class="printerHasPrintedIcon"/>
                    <span v-html="printerHasPrintedText"/>
                </span>
                <div v-if="!printer.isReachable" class="row">
                    <div class="col-12">
                        <div class="alert alert-soft-danger mt-2 w-100">
                            <i class="fa fa-exclamation-triangle mr-2"/>
                            <span v-html="$t(`ControlPanelPrinter.printerIsUnavailable.${printerType}`)"/>
                        </div>
                    </div>
                </div>
                <div v-if="printerType === 'corpus' && !printer.RIP.isReachable" class="row">
                    <div class="col-12">
                        <div class="alert alert-soft-danger mt-2 w-100">
                            <i class="fa fa-exclamation-triangle mr-2"/>
                            <span v-html="$t(`ControlPanelPrinter.corpusPrinterRIPIsUnavailable`)"/>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ControlPanelPrinter",
    props: {
        printer: {
            type: Object,
            required: true,
        },
        printerType: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters("order", { printingOrder: "printingOrder" }),
        printerTitle() {
            return this.printerType === "cover" ? this.$t("ControlPanelPrinter.coverPrinter") : this.$t("ControlPanelPrinter.corpusPrinter");
        },
        printerIsEmptyText() {
            return this.printer.isEmpty ? this.$t("ControlPanelPrinter.printerIsEmpty") : this.$t("ControlPanelPrinter.printerIsFull");
        },
        printerIsEmptyClass() {
            return this.printer.isEmpty ? "badge-soft-secondary" : "badge-soft-info";
        },
        printerHasPrintedText() {
            return this.printer.hasPrinted ? this.$t("ControlPanelPrinter.printerHasPrinted") : this.$t("ControlPanelPrinter.printerHasNotPrinted");
        },
        printerHasPrintedClass() {
            return this.printer.hasPrinted ? "badge-soft-success" : "badge-soft-info";
        },
        printerHasPrintedIcon() {
            return this.printer.hasPrinted ? "fa fa-check" : "fas fa-print";
        },
        printerAvailabilityClasses() {
            return this.printer.isReachable ? "fa-wifi text-success" : "fa-ban text-danger";
        },
    },
};
</script>

<style scoped>

</style>