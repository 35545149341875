<template>
    <header id="header" class="navbar navbar-expand-sm navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered">
        <div class="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
            <div class="navbar-brand-wrapper">
                <router-link class="navbar-brand" to="/" aria-label="Front">
                    <img class="navbar-brand-logo" :src="G1Logo" alt=""/>
                </router-link>
            </div>
            <div class="navbar-nav-wrap-content-right">
                <ul class="navbar-nav align-items-center flex-row">
                    <li class="nav-item d-none d-sm-inline-block">
                        <div class="hs-unfold">
                            <a id="printing-queue-modal-button" href="#" class="navbar-nav-link nav-link" @click.prevent="showPrintingQueueModal">
                                <i class="fa fa-print"/>
                                <i v-if="printingQueueJobs.length" id="printing-queue-running-icon" class="fa"
                                   :class="printingQueueRunningIconClasses"/>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div id="navbarNavMenu" class="navbar-nav-wrap-content-left collapse navbar-collapse">
                <div class="navbar-body">
                    <ul class="navbar-nav mr-auto">
                        <li>
                            <router-link class="navbar-nav-link nav-link" :class="{ active: $route.name === 'Orders' }" to="/">
                                <i class="fa fa-box-open mr-2"/>
                                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate" v-html="$t('NavBar.orders')"/>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="navbar-nav-link nav-link" :class="{ active: $route.name === 'ControlPanel' }" to="/control-panel">
                                <i v-if="!robotStatus.canPrint" class="fa fa-exclamation-triangle text-danger mr-2"/>
                                <i class="fa fa-tachometer-alt mr-2"/>
                                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate" v-html="$t('NavBar.controlPanel')"/>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="navbar-nav-link nav-link" :class="{ active: $route.name === 'History' }" to="/history">
                                <i class="fa fa-history mr-2"/>
                                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate" v-html="$t('NavBar.history')"/>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from "vuex";
// import G1Logo from "@/assets/img/logo_le-lys-bleu.png";
import Config from "../../../config";

export default {
    name: "NavBar",
    data() {
        return { G1Logo: require(`@/assets/img/${Config.app.logo}`) };
    },
    computed: {
        ...mapGetters("robotStatus", { robotStatus: "robotStatus" }),
        ...mapGetters("order", {
            printingOrder: "printingOrder",
            printingQueueJobs: "printingQueueJobs",
        }),
        printingQueueRunningIconClasses() {
            return this.printingOrder ? "fa-sync fa-spin text-primary" : "fa-clock text-warning";
        },
    },
    methods: {
        showPrintingQueueModal() {
            this.$emit("show-printing-queue-modal");
        },
    },
};
</script>

<style lang="scss" scoped>
    .navbar-nav-link {
        color: #404040;
        transition: all 0.5s ease;
    }

    .navbar-brand-logo {
        min-width: unset;
        max-height: 62px !important;
    }

    #printing-queue-modal-button {
        font-size: 1.5rem;
    }

    #printing-queue-running-icon {
        position: absolute;
        right: 5%;
        bottom: 20%;
        font-size: 1rem;
    }
    .version {
        font-size: 8px;
    }
</style>