<template>
    <div id="control-panel-production" class="row">
        <div class="col-12">
            <h3 class="text-primary">
                <i class="fa fa-book mr-2"/>
                <span v-html="$t('ControlPanelProduction.production')"/>
            </h3>
            <hr/>
        </div>
        <div class="col-6">
            <a id="current-job-panel" class="card card-hover-shadow h-100" href="#" @click.prevent="showPrintingOrder">
                <div class="card-body text-dark">
                    <h6 class="card-subtitle text-primary" v-html="$t('ControlPanelProduction.currentJob')"/>
                    <div v-if="!robotCurrentJob.corpus.tilesPerFace" id="no-current-job" key="no-current-job"
                         class="d-flex align-items-center justify-content-center h-100">
                        <h2 class="text-muted text-center">
                            <i class="fa fa-check mr-2"/>
                            <span v-html="$t('ControlPanelProduction.noCurrentJob')"/>
                        </h2>
                    </div>
                    <div v-else key="current-job">
                        <div class="row">
                            <div class="col-12">
                                <i class="fa fa-clock text-primary mr-2"/>
                                <span v-html="currentJobUpdatedAtText"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <i class="fa fa-book text-primary"/>
                                <i class="fa fa-arrows-alt-v text-primary mr-1"/>
                                <span v-html="$t('ControlPanelProduction.dimensions', robotCurrentJob.dimensions)"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <i class="fa fa-book-open text-primary mr-2"/>
                                <span v-html="tilesPerFaceText"/>
                            </div>
                        </div>
                        <div v-if="printingOrder" class="row">
                            <div class="col-12">
                                <hr class="my-2"/>
                                <button class="btn btn-primary btn-block">
                                    <i class="fa fa-print mr-2"/>
                                    <span v-html="$t('ControlPanelProduction.goToPrintingOrder')"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6">
            <a class="card card-hover-shadow h-100" href="#">
                <div class="card-body text-dark">
                    <h6 class="card-subtitle text-primary d-inline" v-html="$t('ControlPanelProduction.bookBoxes')"/>
                    <span class="ml-2 badge" :class="robotBookBoxesUnlockClass">
                        <i :class="`fa fa-${robotBookBoxesUnlockIcon} mr-2`"/>
                        <span v-html="robotBookBoxesUnlockText"/>
                    </span>
                    <span class="ml-2 badge badge-secondary">
                        <i :class="`fa fa-${robotBookBoxesOpenIcon} mr-2`"/>
                        <span v-html="robotBookBoxesOpenText"/>
                    </span>
                    <div class="row mt-2">
                        <div v-if="robotBookBoxes.door.isUnlocked" class="col-12">
                            <div class="alert alert-soft-danger mt-2 w-100">
                                <i class="fa fa-exclamation-triangle mr-2"/>
                                <span v-html="$t('ControlPanelProduction.robotCantPrintIfBookBoxesIsUnlocked')"/>
                            </div>
                        </div>
                        <div v-if="robotStatus.cycleIsLocked" class="col-12">
                            <div class="alert alert-soft-danger mt-2 w-100">
                                <i class="fa fa-exclamation-triangle mr-2"/>
                                <span v-html="$t('ControlPanelProduction.robotCantPrintIfCycleIsLocked')"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <div class="text-center" v-html="$t('ControlPanelProduction.smallBookBox')"/>
                            <div class="text-center">
                                <span v-html="`${robotBookBoxes.smallBookBox.bookCount} / ${robotBookBoxes.smallBookBox.max}`"/>
                                <span v-if="robotStatus.isSmallBookBoxFull" class="ml-2 badge badge-soft-danger"
                                      v-html="$t('ControlPanelProduction.boxFull')"/>
                            </div>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar"
                                     :style="{ width: `${robotStatus.smallBookBoxFillingPercent}%` }"/>
                            </div>
                        </div>
                        <div class="col">
                            <div class="text-center" v-html="$t('ControlPanelProduction.largeBookBox')"/>
                            <div class="text-center">
                                <span v-html="`${robotBookBoxes.largeBookBox.bookCount} / ${robotBookBoxes.largeBookBox.max}`"/>
                                <span v-if="robotStatus.isLargeBookBoxFull" class="ml-2 badge badge-soft-danger"
                                      v-html="$t('ControlPanelProduction.boxFull')"/>
                            </div>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar"
                                     :style="{ width: `${robotStatus.largeBookBoxFillingPercent}%` }"/>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2"/>
                    <SubmitButton v-if="!robotBookBoxes.isUnlocked" :loading="loading.unlockBookBoxes"
                                  classes="btn btn-block btn-primary text-uppercase"
                                  :disabled="unlockRobotBookBoxesDoorRequestSent" @click.prevent="unlockBookBoxes">
                        <i class="fa fa-lock-open mr-2"/>
                        <span v-html="openBookBoxesDoorButtonText"/>
                    </SubmitButton>
                    <div v-if="unlockRobotBookBoxesDoorRequestSent" class="d-flex justify-content-center">
                        <SubmitButton :loading="loading.unlockBookBoxes" class="text-center"
                                      classes="btn btn-secondary mt-2 text-center" @click.prevent="cancelUnlockBookBoxesRequest">
                            <i class="fa fa-times-circle mr-2"/>
                            <span v-html="$t('ControlPanelProduction.cancel')"/>
                        </SubmitButton>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatTime } from "@/helpers/functions/Date";
import SubmitButton from "@/components/shared/Forms/SubmitButton";
import Swal from "sweetalert2";

export default {
    name: "ControlPanelProduction",
    components: { SubmitButton },
    data() {
        return { loading: { unlockBookBoxes: false } };
    },
    computed: {
        ...mapGetters("robotStatus", {
            robotStatus: "robotStatus",
            robotCurrentJob: "robotCurrentJob",
            robotBookBoxes: "robotBookBoxes",
            unlockRobotBookBoxesDoorRequestSent: "unlockRobotBookBoxesDoorRequestSent",
        }),
        ...mapGetters("order", { printingOrder: "printingOrder" }),
        tilesPerFaceText() {
            return this.$tc("ControlPanelProduction.tilesPerFace", this.robotCurrentJob.corpus.tilesPerFace, this.robotCurrentJob.corpus);
        },
        robotBookBoxesOpenText() {
            if (this.robotBookBoxes.door.isOpen) {
                return this.$t("ControlPanelProduction.bookBoxesAreOpen");
            }
            return this.$t("ControlPanelProduction.bookBoxesAreClosed");
        },
        robotBookBoxesOpenIcon() {
            return this.robotBookBoxes.door.isOpen ? "box-open" : "box";
        },
        robotBookBoxesUnlockText() {
            if (this.robotBookBoxes.door.isUnlocked) {
                return this.$t("ControlPanelProduction.bookBoxesAreUnlocked");
            }
            return this.$t("ControlPanelProduction.bookBoxesAreLocked");
        },
        robotBookBoxesUnlockIcon() {
            return this.robotBookBoxes.door.isUnlocked ? "lock-open" : "lock";
        },
        robotBookBoxesUnlockClass() {
            return this.robotBookBoxes.door.isUnlocked ? "badge-soft-success" : "badge-soft-danger";
        },
        openBookBoxesDoorButtonText() {
            if (!this.unlockRobotBookBoxesDoorRequestSent) {
                return this.$t("ControlPanelProduction.unlockBookBoxes");
            }
            return this.$t("ControlPanelProduction.unlockBookBoxesRequestSent");
        },
        currentJobUpdatedAtText() {
            if (this.robotCurrentJob) {
                return this.$t("ControlPanelProduction.updatedAt", { time: formatTime(new Date(this.robotCurrentJob.updatedAtTimestamp)) });
            }
            return "";
        },
    },
    methods: {
        confirmUnlockBookBoxes() {
            return Swal.fire({
                title: this.$t("ControlPanelProduction.areYouSure"),
                text: this.$t("ControlPanelProduction.youWillUnlockBookBoxes"),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                confirmButtonText: this.$t("ControlPanelProduction.confirm"),
                cancelButtonText: this.$t("ControlPanelProduction.cancel"),
            });
        },
        async unlockBookBoxes() {
            try {
                const { isConfirmed } = await this.confirmUnlockBookBoxes();
                if (isConfirmed) {
                    this.loading.unlockBookBoxes = true;
                    await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { unlockBookBoxes: true } });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading.unlockBookBoxes = false;
            }
        },
        async cancelUnlockBookBoxesRequest() {
            try {
                this.loading.unlockBookBoxes = true;
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ requestFromOperator: { unlockBookBoxes: false } });
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loading.unlockBookBoxes = false;
            }
        },
        showPrintingOrder() {
            if (this.printingOrder) {
                this.$emit("show-order-print-modal", this.printingOrder);
            } else {
                this.$toasted.info(this.$t("ControlPanelProduction.noPrintingOrderYet"), { icon: "info-circle" });
            }
        },
        formatTime,
    },
};
</script>

<style lang="scss" scoped>
    #no-current-job {
        min-height: 100px;
    }
</style>