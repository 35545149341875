<template>
    <tr>
        <td class="d-flex align-items-center">
            <ProductThumbnail class="order-product-thumbnail" :product="jobProduct"/>
            <div class="ml-2">
                <div class="product-title text-primary" v-html="jobProduct.title"/>
                <div class="font-weight-bold font-italic" v-html="jobProduct.ISBN"/>
                <div class="font-weight-bold font-italic">
                    <span v-html="jobPrintNumberText"/>
                </div>
            </div>
        </td>
        <td class="text-center align-middle">
            <a href="#" @click.prevent="showOrderPrintModal">
                <span v-html="jobOrder.reference"/>
                <i class="fa fa-link ml-2"/>
            </a>
        </td>
        <td width="30%" class="align-middle">
            <div v-if="job.status === 'errored'" class="text-center" v-html="jobErrorText"/>
            <div v-else-if="job.status === 'pending'" class="text-muted text-center d-flex flex-column justify-content-center align-items-center">
                <div class="fa fa-clock fa-2x text-warning"/>
                <span v-html="$t('PrintingQueueModal.pendingJob')"/>
            </div>
            <JobPrintingProgress v-else size="sm" :is-cancel-job-button-shown="false" :job="job"/>
        </td>
        <td width="5%" class="align-middle">
            <RestartJobButton v-if="job.status === 'errored'" :job="job" class="mb-2">
                <i class="fa fa-redo mr-2"/>
                <span v-html="$t('PrintingQueueLine.restartJob')"/>
            </RestartJobButton>
            <CancelJobButton :job="job">
                <i class="fa fa-times mr-2"/>
                <span v-html="$t('PrintingQueueLine.cancelJob')"/>
            </CancelJobButton>
        </td>
    </tr>
</template>

<script>
import { mapGetters } from "vuex";
import Job from "@/classes/Job";
import ProductThumbnail from "@/components/shared/Products/ProductThumbnail";
import CancelJobButton from "@/components/shared/Jobs/CancelJobButton";
import JobPrintingProgress from "@/components/shared/Jobs/JobPrintingProgress";
import RestartJobButton from "@/components/shared/Jobs/RestartJobButton";

export default {
    name: "PrintingQueueLine",
    components: { RestartJobButton, JobPrintingProgress, CancelJobButton, ProductThumbnail },
    props: {
        job: {
            type: Job,
            required: true,
        },
    },
    computed: {
        ...mapGetters("order", { orders: "orders" }),
        jobOrder() {
            return this.orders.find(({ _id }) => _id === this.job.orderId);
        },
        jobProduct() {
            return this.jobOrder.products.find(({ _id }) => _id === this.job.productId);
        },
        jobErrorText() {
            return this.job.errorType ? this.$t(`Error.${this.job.errorType}`) : this.$t("PrintingQueueLine.unknownError");
        },
        jobPrintNumberText() {
            return this.$t("PrintingQueueLine.jobPrintNumber", this.job);
        },
    },
    methods: {
        showOrderPrintModal() {
            this.$emit("show-order-print-modal", this.jobOrder);
        },
    },
};
</script>

<style lang="scss" scoped>
    .order-product-thumbnail {
        height: 50px;
        width: auto;
    }

    .product-title {
        font-size: 1rem;
    }
</style>