<template>
    <div id="job-printing-progress" :class="`job-printing-progress-${size}`">
        <div class="row align-items-center">
            <div class="progress-bar-wrapper col-12">
                <div class="row justify-content-center mb-1">
                    <div class="col-lg-12">
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped safe-text" role="progressbar" aria-valuenow="75"
                                 aria-valuemin="0" aria-valuemax="100" :style="{ width: `${progressPercent}%` }" v-html="`${progressPercent}%`"/>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div v-if="isCancelJobButtonShown" class="col-1"/>
                    <div class="col text-center">
                        <h2 class="safe-text text-primary progress-text" v-html="progressText"/>
                    </div>
                    <div v-if="isCancelJobButtonShown" class="col-1">
                        <CancelJobButton :job="job">
                            <i class="fa fa-times"/>
                        </CancelJobButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Job from "@/classes/Job";
import CancelJobButton from "@/components/shared/Jobs/CancelJobButton";

export default {
    name: "JobPrintingProgress",
    components: { CancelJobButton },
    props: {
        job: {
            type: Job,
            required: true,
        },
        isCancelJobButtonShown: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "lg",
        },
    },
    computed: {
        progressText() {
            if (this.job.status !== "done") {
                return this.$t(`JobPrintingProgress.jobStep.${this.job.currentStep}`);
            }
            return "";
        },
        progressPercent() {
            const { availableSteps: availableJobSteps } = Job;
            const currentJobStepIdx = availableJobSteps.findIndex(step => step === this.job.currentStep) - 1;
            const max = availableJobSteps.length - 2;
            return (currentJobStepIdx * 100 / max).toFixed(0);
        },
    },
};
</script>

<style lang="scss" scoped>
.progress {
    height: 15px;
}

.progress-bar-wrapper {
    display: block !important;
}

.job-printing-progress-sm {
    .progress-text {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
}

</style>