<template>
    <SubmitButton :loading="loadings.cancelJob" classes="btn btn-sm btn-danger text-center btn-block" @click.prevent="cancelJob">
        <slot/>
    </SubmitButton>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import Job from "@/classes/Job";
import SubmitButton from "@/components/shared/Forms/SubmitButton";

export default {
    name: "CancelJobButton",
    components: { SubmitButton },
    props: {
        job: {
            type: Job,
            required: true,
        },
    },
    data() {
        return { loadings: { cancelJob: false } };
    },
    methods: {
        ...mapActions("order", { updateOrder: "updateOrder" }),
        confirmCancelJob() {
            const cancelTextField = this.job.status === "errored" ? "cancelErroredJobIsIrreversible" : "cancelProcessingJobIsIrreversible";
            return Swal.fire({
                title: this.$t("CancelJobButton.areYouSure"),
                text: this.$t(`CancelJobButton.${cancelTextField}`),
                icon: "warning",
                animation: false,
                showCancelButton: true,
                cancelButtonText: this.$t("CancelJobButton.cancel"),
            });
        },
        async cancelJob() {
            try {
                const { isConfirmed } = await this.confirmCancelJob();
                if (isConfirmed) {
                    this.loadings.cancelJob = true;
                    const { orderId, productId, _id: jobId } = this.job;
                    const { data } = await this.$gutenbergOneRobotAPI.patchJob(orderId, productId, jobId, { status: "canceled" });
                    this.updateOrder(data);
                    this.$toasted.success(this.$t("CancelJobButton.jobCanceled"), { icon: "check" });
                }
            } catch (e) {
                this.$error.display(e);
            } finally {
                this.loadings.cancelJob = false;
            }
        },
    },
};
</script>

<style scoped>

</style>