import { getProp } from "@/helpers/functions/Class";
import Job from "@/classes/Job";

class Product {
    constructor(product = null) {
        this._id = getProp(product, "_id");
        this.title = getProp(product, "title");
        this.ISBN = getProp(product, "ISBN");
        this.dimensions = {
            width: getProp(product, "dimensions.width"),
            height: getProp(product, "dimensions.height"),
            thickness: getProp(product, "dimensions.thickness"),
        };
        this.paging = {
            total: getProp(product, "paging.total"),
            color: getProp(product, "paging.color"),
        };
        this.photosURL = { cover: { first: getProp(product, "photosURL.cover.first") } };
        this.quantity = getProp(product, "quantity");
        this.options = { dedication: getProp(product, "options.dedication") };
        this.files = {
            status: getProp(product, "files.status"),
            download: { percent: getProp(product, "files.download.percent") },
        };
        this.jobs = getProp(product, "jobs", [], jobs => jobs.map(job => new Job(job)));
        this.createdAt = getProp(product, "createdAt", undefined, createdAt => new Date(createdAt));
        this.updatedAt = getProp(product, "updatedAt", undefined, updatedAt => new Date(updatedAt));
    }

    get jobsInPrintingQueue() {
        return this.jobs.filter(({ isInPrintingQueue }) => isInPrintingQueue);
    }

    get doneJobs() {
        return this.jobs.filter(({ status }) => status === "done");
    }

    get erroredJobs() {
        return this.jobs.filter(({ status }) => status === "errored");
    }

    get canceledJobs() {
        return this.jobs.filter(({ status }) => status === "canceled");
    }

    get processingJob() {
        return this.jobs.find(({ status }) => status === "processing");
    }

    get isInPrintingQueue() {
        return this.jobs.some(({ isInPrintingQueue }) => isInPrintingQueue);
    }

    get isPrinting() {
        return this.jobs.some(({ status }) => status === "processing");
    }

    get isFullyPrinted() {
        return this.doneJobs.length >= this.quantity;
    }

    get isPrintable() {
        return this.files.status === "printable";
    }
}

export default Product;