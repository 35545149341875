<template>
    <div id="control-panel" class="content container-fluid">
        <PageHeader>
            <i v-if="!robotStatus.canPrint" class="fa fa-exclamation-triangle text-danger mr-2"/>
            <i class="fa fa-tachometer-alt mr-2"/>
            <span v-html="$t('ControlPanel.controlPanel')"/>
        </PageHeader>
        <div class="row">
            <ControlPanelRobot class="col-6"/>
            <ControlPanelAlarms class="col-6"/>
        </div>
        <hr/>
        <div class="row">
            <ControlPanelProduction class="col-8" @show-order-print-modal="showOrderPrintModal"/>
            <ControlPanelPrinters class="col-4"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/shared/Misc/PageHeader/PageHeader";
import ControlPanelRobot from "@/components/ControlPanel/ControlPanelRobot";
import ControlPanelProduction from "@/components/ControlPanel/ControlPanelProduction";
import ControlPanelPrinters from "@/components/ControlPanel/ControlPanelPrinters/ControlPanelPrinters";
import ControlPanelAlarms from "@/components/ControlPanel/ControlPanelAlarms";

export default {
    name: "ControlPanel",
    components: { ControlPanelAlarms, ControlPanelPrinters, ControlPanelProduction, ControlPanelRobot, PageHeader },
    computed: { ...mapGetters("robotStatus", { robotStatus: "robotStatus" }) },
    methods: {
        showOrderPrintModal(order) {
            this.$emit("show-order-print-modal", order);
        },
    },
};
</script>

<style scoped>

</style>