import { render, staticRenderFns } from "./RestartJobButton.vue?vue&type=template&id=b504bac6&scoped=true&"
import script from "./RestartJobButton.vue?vue&type=script&lang=js&"
export * from "./RestartJobButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b504bac6",
  null
  
)

export default component.exports