<template>
    <div class="content container-fluid">
        <PageHeader>
            <i class="fa fa-history mr-2"/>
            <span v-html="$t('History.history')"/>
        </PageHeader>
        <div class="card">
            <v-client-table :data="formattedJobs" :columns="historyTable.columns" :options="historyTable.options">
                <div slot="reference" slot-scope="props">
                    <div>
                        <i class="fa fa-box text-primary mr-2"/>
                        <span class="font-weight-bolder" v-html="props.row.reference"/>
                    </div>
                </div>

                <div slot="printedAt" slot-scope="props">
                    <div>
                        <i class="fa fa-calendar text-primary mr-1"/>
                        <span class="mr-2" v-html="formatDate(props.row.printedAt)"/>
                        <i class="fa fa-clock text-primary mr-1"/>
                        <span v-html="formatTime(props.row.printedAt)"/>
                    </div>
                </div>
            </v-client-table>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/shared/Misc/PageHeader/PageHeader";
import { formatDate, formatTime } from "@/helpers/functions/Date";

export default {
    name: "History",
    components: { PageHeader },
    data() {
        return {
            jobs: [],
            historyTable: {
                columns: ["reference", "ISBN", "printedAt"],
                options: {
                    headings: {
                        reference: this.$t("History.reference"),
                        ISBN: this.$t("History.isbnTitle"),
                        printedAt: this.$t("History.printedAt"),
                    },
                    dateColumns: ["printedAt"],
                    filterable: ["reference", "ISBN", "printedAt"],
                    orderBy: {
                        column: "printedAt",
                        ascending: false,
                    },
                    perPage: 10,
                    perPageValues: [5, 10, 25, 50, 100],
                    sortable: ["printedAt"],
                    columnsClasses: {
                        reference: "align-middle",
                        ISBN: "align-middle",
                        printedAt: "text-center align-middle",
                    },
                },
            },
        };
    },
    computed: {
        formattedJobs() {
            return this.jobs.map(job => ({
                ...job,
                ISBN: `${job.ISBN} - ${job.title}`,
            }));
        },
    },
    created() {
        this.fetchJobs();
    },
    methods: {
        formatDate,
        formatTime,
        async fetchJobs() {
            try {
                const response = await this.$gutenbergOneRobotAPI.getHistoryPrintedOrder();
                this.jobs = response.data;
            } catch (e) {
                this.$error.display(e);
            }
        },
    },
};
</script>

<style>
</style>