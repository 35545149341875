<template>
    <div id="app">
        <div v-if="!isAppReady" id="loading" class="text-center">
            <h1>
                CHARGEMENT...
            </h1>
            <button class="btn btn-secondary btn-sm" @click="reload">
                <i class="fa fa-sync fa-spin"/>
            </button>
        </div>
        <div v-else id="gutenberg-one-robot-web">
            <NavBar @show-printing-queue-modal="showPrintingQueueModal"/>
            <main id="content" role="main" class="main">
                <router-view @show-order-print-modal="showOrderPrintModal"/>
            </main>
            <OrderPrintModal ref="orderPrintModal"/>
            <PrintingQueueModal ref="printingQueueModal" @show-order-print-modal="showOrderPrintModal"/>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import NavBar from "@/components/NavBar/NavBar";
import { setDelay } from "@/helpers/functions/Misc";
import OrderPrintModal from "@/components/shared/Orders/Print/OrderPrint/OrderPrintModal";
import PrintingQueueModal from "@/components/shared/Orders/Print/PrintingQueueModal/PrintingQueueModal";

export default {
    name: "App",
    components: { PrintingQueueModal, OrderPrintModal, NavBar },
    data() {
        return { version: "1.3.0" };
    },
    computed: {
        ...mapGetters("order", { orders: "orders" }),
        ...mapGetters("robotStatus", { robotStatus: "robotStatus" }),
        isAppReady() {
            return !!this.orders && !!this.robotStatus._id;
        },
    },
    watch: {
        "robotStatus.askOperator.whenToOpenGrip": {
            handler(value) {
                if (value) {
                    this.askWhenToOpenGrip();
                }
            },
            immediate: true,
        },
    },
    async created() {
        try {
            // eslint-disable-next-line
            console.log(`Version du Robot (web): ${this.version}`);
            this.initializeSocketIO();
            await this.getAndSetOrders();
            await this.getAndSetRobotStatus();
            await this.setRobotStatusLongPolling();
        } catch (e) {
            this.$error.display(e);
        }
    },
    methods: {
        ...mapActions("order", { getAndSetOrders: "getAndSetOrders" }),
        ...mapActions("robotStatus", { getAndSetRobotStatus: "getAndSetRobotStatus", setRobotStatusLongPolling: "setRobotStatusLongPolling" }),
        ...mapActions("socketIO", { initializeSocketIO: "initializeSocketIO" }),
        reload() {
            window.location.reload();
        },
        async replyWhenToOpenGrip(openAtTheBeginning) {
            try {
                const grip = { openAtTheBeginning, openAtTheEnd: !openAtTheBeginning };
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ grip });
                Swal.fire({
                    text: this.$t("App.waitingForRobotResponse"),
                    didOpen: async() => {
                        Swal.showLoading();
                        while (this.robotStatus.askOperator.whenToOpenGrip) {
                            await setDelay(500);
                        }
                        Swal.close();
                    },
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                });
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ grip: { openAtTheBeginning: false, openAtTheEnd: false } });
            } catch (e) {
                this.$error.display(e);
                await this.$gutenbergOneRobotAPI.updateRobotStatus({ grip: { openAtTheBeginning: false, openAtTheEnd: false } });
                this.askWhenToOpenGrip();
            }
        },
        async askWhenToOpenGrip() {
            const { isConfirmed } = await Swal.fire({
                title: this.$t("App.requiredAction"),
                icon: "warning",
                text: this.$t("App.whenToOpenGrip"),
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: true,
                showDenyButton: true,
                animation: false,
                confirmButtonText: this.$t("App.atTheBeginning"),
                denyButtonText: this.$t("App.atTheEnd"),
            });
            this.replyWhenToOpenGrip(isConfirmed);
        },
        showOrderPrintModal(order) {
            this.$refs.orderPrintModal.show(order._id);
        },
        showPrintingQueueModal() {
            this.$refs.printingQueueModal.show();
        },
    },
};
</script>

<style>
</style>