import { getProp } from "@/helpers/functions/Class";
import { getRobotAlarmErrorNames, getRobotAlarmWarningNames } from "@/helpers/functions/Robot";
import Config from "../../config";
import i18n from "../plugins/vue-i18n";

class RobotStatus {
    // eslint-disable-next-line max-lines-per-function
    constructor(robotStatus = null) {
        this._id = getProp(robotStatus, "_id");
        this.fault = {
            fromRobot: getProp(robotStatus, "fault.fromRobot"),
            fromSoBook: getProp(robotStatus, "fault.fromSoBook"),
            acknowledged: getProp(robotStatus, "fault.acknowledged"),
        };
        this.requestFromOperator = {
            start: getProp(robotStatus, "requestFromOperator.start"),
            stop: getProp(robotStatus, "requestFromOperator.stop"),
            unlockBookBoxes: getProp(robotStatus, "requestFromOperator.unlockBookBoxes"),
            acknowledgeAlarms: getProp(robotStatus, "requestFromOperator.acknowledgeAlarms"),
        };
        this.askOperator = { whenToOpenGrip: getProp(robotStatus, "askOperator.whenToOpenGrip") };
        this.printers = {
            cover: {
                isReachable: getProp(robotStatus, "printers.cover.isReachable"),
                isEmpty: getProp(robotStatus, "printers.cover.isEmpty"),
                hasPrinted: getProp(robotStatus, "printers.cover.hasPrinted"),
                isOutOfInk: getProp(robotStatus, "printers.cover.isOutOfInk"),
                isOutOfPaper: getProp(robotStatus, "printers.cover.isOutOfPaper"),
                hasPaperJam: getProp(robotStatus, "printers.cover.hasPaperJam"),
            },
            corpus: {
                isReachable: getProp(robotStatus, "printers.corpus.isReachable"),
                isEmpty: getProp(robotStatus, "printers.corpus.isEmpty"),
                hasPrinted: getProp(robotStatus, "printers.corpus.hasPrinted"),
                isOutOfInk: getProp(robotStatus, "printers.corpus.isOutOfInk"),
                isOutOfPaper: getProp(robotStatus, "printers.corpus.isOutOfPaper"),
                hasPaperJam: getProp(robotStatus, "printers.corpus.hasPaperJam"),
                RIP: { isReachable: getProp(robotStatus, "printers.corpus.RIP.isReachable") },
            },
        };
        this.grip = {
            openAtTheBeginning: getProp(robotStatus, "grip.openAtTheBeginning"),
            openAtTheEnd: getProp(robotStatus, "grip.openAtTheEnd"),
        };
        this.state = getProp(robotStatus, "state");
        this.mode = getProp(robotStatus, "mode");
        this.cycleIsLocked = getProp(robotStatus, "cycleIsLocked");
        this.currentJob = {
            dimensions: {
                width: getProp(robotStatus, "currentJob.dimensions.width"),
                height: getProp(robotStatus, "currentJob.dimensions.height"),
                thickness: getProp(robotStatus, "currentJob.dimensions.thickness"),
            },
            corpus: { tilesPerFace: getProp(robotStatus, "currentJob.corpus.tilesPerFace") },
            updatedAtTimestamp: getProp(robotStatus, "currentJob.updatedAtTimestamp"),
        };
        this.bookBoxes = {
            door: {
                isOpen: getProp(robotStatus, "bookBoxes.door.isOpen"),
                isUnlocked: getProp(robotStatus, "bookBoxes.door.isUnlocked"),
            },
            smallBookBox: {
                bookCount: getProp(robotStatus, "bookBoxes.smallBookBox.bookCount"),
                max: parseInt(Config.bookBoxes.smallBookBox.max),
            },
            largeBookBox: {
                bookCount: getProp(robotStatus, "bookBoxes.largeBookBox.bookCount"),
                max: parseInt(Config.bookBoxes.largeBookBox.max),
            },
        };
        this.alarms = {
            errors: {
                0: getProp(robotStatus, "alarms.errors.0"),
                1: getProp(robotStatus, "alarms.errors.1"),
                2: getProp(robotStatus, "alarms.errors.2"),
            },
            warnings: { 0: getProp(robotStatus, "alarms.warnings.0") },
        };
        this.timestamps = {
            fromSoBook: getProp(robotStatus, "timestamps.fromSoBook"),
            fromRobot: getProp(robotStatus, "timestamps.fromRobot"),
        };
    }

    get isInIdleMode() {
        const idleModes = [0, 1, 2];
        return idleModes.includes(this.mode);
    }

    get isInProducingMode() {
        const producingModes = [20];
        return producingModes.includes(this.mode);
    }

    get smallBookBoxFillingPercent() {
        return Math.round(this.bookBoxes.smallBookBox.bookCount * 100 / this.bookBoxes.smallBookBox.max);
    }

    get largeBookBoxFillingPercent() {
        return Math.round(this.bookBoxes.largeBookBox.bookCount * 100 / this.bookBoxes.largeBookBox.max);
    }

    get isSmallBookBoxFull() {
        return this.bookBoxes.smallBookBox.bookCount === this.bookBoxes.smallBookBox.max;
    }

    get isLargeBookBoxFull() {
        return this.bookBoxes.largeBookBox.bookCount === this.bookBoxes.largeBookBox.max;
    }

    get arePrintersReachable() {
        return this.printers.cover.isReachable && this.printers.corpus.isReachable && this.printers.corpus.RIP.isReachable;
    }

    get canPrint() {
        return !this.cycleIsLocked && this.mode === 20 && !this.bookBoxes.door.isUnlocked && !this.errors.length && this.arePrintersReachable;
    }

    get errors() {
        const booleanErrors = [
            this.alarms.errors["0"],
            this.alarms.errors["1"],
            this.alarms.errors["2"],
        ].reduce((acc, int) => acc + int.toString(2).padStart(32, "0").split("").reverse().join(""), "").split("").map(char => char === "1");
        const errors = [];
        const robotAlarmErrorNames = getRobotAlarmErrorNames();
        for (let i = 0; i < booleanErrors.length; i++) {
            if (booleanErrors[i]) {
                let robotAlarmErrorName = robotAlarmErrorNames[i];
                robotAlarmErrorName = i18n.t(`Robot.Error.${robotAlarmErrorName}`);
                // robotAlarmErrorName = robotAlarmErrorName.replace(/_/gu, " ");
                errors.push(robotAlarmErrorName);
            }
        }
        return errors;
    }

    get warnings() {
        const bitsWarnings = this.alarms.warnings["0"].toString(2).padStart(32, "0").split("").reverse().join("");
        const booleanWarnings = bitsWarnings.split("").map(char => char === "1");
        const warnings = [];
        const robotAlarmWarningNames = getRobotAlarmWarningNames();
        for (let i = 0; i < booleanWarnings.length; i++) {
            if (booleanWarnings[i]) {
                let robotAlarmWarningName = robotAlarmWarningNames[i];
                robotAlarmWarningName = i18n.t(`Robot.Warning.${robotAlarmWarningName}`);
                // robotAlarmWarningName = robotAlarmWarningName.replace(/_/gu, " ");
                warnings.push(robotAlarmWarningName);
            }
        }
        return warnings;
    }
}

export default RobotStatus;