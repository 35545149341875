<template>
    <div id="printing-queue">
        <div v-if="!printingQueueJobs.length" id="empty-printing-queue">
            <h2 class="text-center text-muted">
                <i class="fa fa-print mr-2"/>
                <span v-html="$t('PrintingQueue.printingQueueEmpty')"/>
            </h2>
        </div>
        <div v-else>
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th v-html="$t('PrintingQueue.product')"/>
                        <th class="text-center" v-html="$t('PrintingQueue.order')"/>
                        <th class="text-center" v-html="$t('PrintingQueue.progress')"/>
                        <th class="text-center" v-html="$t('PrintingQueue.actions')"/>
                    </tr>
                </thead>
                <tbody>
                    <PrintingQueueLine v-for="job of printingQueueJobs" :key="job._id" :job="job" @show-order-print-modal="showOrderPrintModal"/>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrintingQueueLine from "@/components/shared/Orders/Print/PrintingQueueModal/PrintingQueue/PrintingQueueLine";

export default {
    name: "PrintingQueue",
    components: { PrintingQueueLine },
    computed: { ...mapGetters("order", { printingQueueJobs: "printingQueueJobs" }) },
    methods: {
        showOrderPrintModal(order) {
            this.$emit("show-order-print-modal", order);
        },
    },
};
</script>

<style scoped>

</style>